import { useEffect } from 'react';
import { PAGINAS } from '~/interfaces';

export const useTitle = (title?: PAGINAS | string) => {
	useEffect(() => {
		window.document.title = title 
			? title + ' - Restaurantes y Pastelerías Panamá'
			: 'Restaurantes y Pastelerías Panamá';
		return () => {
			window.document.title = 'Restaurantes y Pastelerías Panamá';
		};
	}, [title]);
};

export default useTitle;