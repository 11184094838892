import React from 'react';
import { AddFillIcon, DeleteIcon, MinusIcon } from '~/assets/icons';
import { Grid, LoadingRing, RipplesButtons, Typography } from '..';
import { LoadingContainer, QuantityContainer } from './styled';
type Props = {
    handleRemove: () => void,
    handleAdd: () => void,
    nCantidad: number,
	loadingLeftSide?: boolean,
	loadingRightSide?: boolean
};
const Quantity : React.FC<Props> = ({ nCantidad, handleRemove, handleAdd, loadingLeftSide,loadingRightSide } : Props) => {
	const disabled = loadingLeftSide || loadingRightSide;
	return (
		<QuantityContainer>
			<RipplesButtons disabled={disabled} onClick={handleRemove} className="minus button">
				{
					loadingLeftSide ? <LoadingContainer>
						<div className='svgContainer'>
							<LoadingRing color='greyCard' />
						</div>
					</LoadingContainer> : (nCantidad === 1 ? <DeleteIcon /> : <MinusIcon />)
				}
			</RipplesButtons>
			<Grid minwidth='20px' alignItems='center' justifyContent='center' item container flex>
				<Typography variant='caption'>{nCantidad}</Typography>
			</Grid>
			<RipplesButtons disabled={disabled || nCantidad >= 15} onClick={handleAdd} className="add button">
				{loadingRightSide ? <LoadingContainer>
					<div className='svgContainer'>
						<LoadingRing color='greyCard' />
					</div>
				</LoadingContainer> : <AddFillIcon color={disabled || nCantidad >= 15 ? 'greyCard' : 'black'} />}
			</RipplesButtons>

		</QuantityContainer>
	);
};
export default (Quantity);