import React from 'react';
import { HideShowComponentContainer } from './styled';
type Props = {
  children: JSX.Element | JSX.Element[],
  show: boolean,
};
const HideShowComponent : React.FC<Props> = ({ show, children } : Props) => {
	return (
		<HideShowComponentContainer show={show}>
			{show ? children : <></>}
		</HideShowComponentContainer>
	);
};
export default (HideShowComponent);