import styled, { css } from 'styled-components';
export const FormContainer = styled.div`
    --error-color: ${props =>props.theme.text.font.red};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-family: ${props =>props.theme.text.font.primary} !important;
    position: relative;
    button {
      background-image: none !important;
      background-color: ${props =>props.theme.colors.primary} !important;
      border-radius: 22px !important;
      text-transform: capitalize !important;

      svg {
        display: none;
      }
    }
    
`;
export interface LoadingContainerProps {
  showGeneralLoading?: boolean;
}

export const LoadingContainer = styled.div<LoadingContainerProps>`
    position: absolute;
    display: flex;
    box-sizing: border-box;
    top: 0;
    bottom: 0;
    justify-content: center;
    width: 100%;
    min-height: 200px;
    z-index: 100000;
    background-color: ${props => props.theme.colors.white};
    .container {
      height: 200px;
    }
    ${props => props.showGeneralLoading ? css`
        display: flex;
        opacity: 1;

        ` : css`
        display: none;

    `}
`;
