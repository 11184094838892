import styled, { css } from 'styled-components';

export const DetalleLayout = styled.div`
    box-sizing: border-box;
    flex: auto;
    display: flex;
    border-radius: 7px;
    background-color: ${props => props.theme.colors.white};
    flex-direction: column;
    padding: 0 0 15px 0;
    margin-bottom: 50px;
    
`;

export const DetalleContainer = styled.div`

    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    flex: auto;
    position: relative;
    @media (max-width: 952px) {
        overflow: auto;
        flex-direction: column;
        padding-bottom: 120px;
    }



`;

export const ImgContainer = styled.div`
    position: sticky;
    box-sizing: border-box;
    padding: 0 50px 15px 50px;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 50%;
    background-color: 'red';
    img {
        box-sizing: border-box;
        border-radius: 22px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    }
    @media (max-width: 952px) {
        max-width: 100%;
        padding: 0;
        .item {
            padding: 0 16px 8px 16px;
        }
        .lastItem {
            padding: 0 16px 0 16px;
        }


        width: 100%;
        img {
        width: 100%;

        }
    }
`;

export interface DividerProps {
    margin?: string;
    padding?: string;
    checked?: boolean;
  }
  
export const Divider = styled.div<DividerProps>`
    height: 1px;
    background-color: ${props => props.theme.colors.borderLightGrey};
    margin: ${props => props.margin};
`;


export const ConfigProductoContainer = styled.div<DividerProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 25px 25px 10px 25px;
    box-sizing: border-box;
    .header {
        margin-bottom: 15px;

    }
    gap: 5px;
    margin-bottom: 15px;
    margin: ${props => props.margin};
    padding: ${props => props.padding};
   
    @media (max-width: 952px) {
        padding: 22px 16px 10px 16px;
        margin-bottom: 0px;

    }

    

`;
export interface ItemRadioProps {
    checked?: boolean;
    checkbox?: boolean;
    disabled?: boolean
}
export const ItemRadio = styled.div<ItemRadioProps>`
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 10px;
    gap: 5px;
    min-height: 60px;
    cursor: ${props => props.disabled ? null : 'pointer'};
    border-radius: 4px;

    ${props => props.checked && !props.checkbox ? css`
    background-color: ${props.theme.colors.yellow};
    ` : css`
    border: 1px solid ${props.theme.colors[props.checked && props.checkbox ? 'checkGreen' : 'grey198']};
    
    `}

`;

export const Tag = styled.span`
    ${props => css`
        font-size: ${props.theme.text.size.caption};
        color: ${props.theme.colors.grey};
        font-family: ${props.theme.text.font.primary};
    `}
    padding: 3px 5px 3px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    max-width: 70px;
    margin-bottom: 5px;

    border: 1px solid ${props => props.theme.colors.grey};
`;


export const Footer = styled.div`
    padding: 22px 10px 35px 10px;
    background-color: ${props => props.theme.colors.white};

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media (max-width: 952px) {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 5;
    }
`;

export const ExtraContainer = styled.div`
    display: flex;
    position: relative;
    border-radius: 5px;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.colors.grey198};
    padding-right: 10px;

`;


export const ScrollContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px 0px 5px 0;
    overflow-y: auto;
    flex: 1;
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.colors.grey198};
    box-sizing: border-box;
    border-radius: 22px;
    @media (max-width: 952px) {
        border: none;
        height: auto;
        flex: none;
    }

`;

export const CloseContainer = styled.div`
    display: flex;
    padding: 20px 15px 10px 15px;
    @media (max-width: 952px) {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 5;
    }
    

`;