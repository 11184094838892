import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 190px;
    flex-wrap: wrap;
    overflow-y: hidden;
    gap: 17px;
    justify-content: space-between;
    position: relative;
`;

export const FloatContainer = styled.div`
   
    background: ${props => props.theme.colors.bgColor};
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 10;
`;