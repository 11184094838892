import React from 'react';
import { CheckIcon, EditIcon, PriorityHighIcon } from '~/assets/icons';
import { useEdit } from '~/hooks';
import Grid from '../Grid';
import Typography from '../Typography';
import { TextFieldContainer, TextFieldLabel, TextFieldInput, TextFieldContainerInput, IconContainer } from './styled';
export type TextFieldProps = {
  label?: string,
  value?: string,
  padding?: string,
  type?: string,
  helperText?: string,
  helperTextAlign?: string,
  error?: boolean,
  width?: string,
  check?: boolean,
  disabled?: boolean,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>,
  placeholder?: string,
  autoCompleteOff?: boolean,
  id?: string,
  margin?: string,
  maxlength?:number,
  minlength?:number,
  max?:number,
  pattern?:string,
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>,
  fullWidth?: boolean,
  edit?: boolean,
  inputId?: string
  onWheel?: React.WheelEventHandler<HTMLInputElement>
};

const TextField : React.FC<TextFieldProps> = ({ padding, label, value, onChange, error, check, helperText, helperTextAlign, type, disabled, onKeyDown, width, placeholder, autoCompleteOff, margin, id, maxlength,minlength,max, onKeyPress, pattern, edit, fullWidth, inputId, onWheel } : TextFieldProps) => {

	const {
		editValue,
		handleEditOnChange
	} = useEdit(onChange, edit);
	const icon = error || check || editValue;

	return (
		<TextFieldContainer fullWidth={fullWidth} id={id} margin={margin} width={width} padding={padding}>
			<Grid container>
				<TextFieldLabel disabled={disabled}>{label}</TextFieldLabel>
			</Grid>
			<TextFieldContainerInput disabled={disabled} error={error} check={check && !editValue} >
				<TextFieldInput id={inputId} onKeyPress={onKeyPress} placeholder={placeholder} onKeyDown={onKeyDown} disabled={disabled} autoComplete={autoCompleteOff ?'one-time-code' : undefined} type={type || 'text'} icon={icon} onChange={editValue ? handleEditOnChange : onChange} onWheel={onWheel} value={value} maxLength={maxlength} minLength={minlength} max={max} pattern={pattern}/>
				{
					icon && <IconContainer >
						{error ? <PriorityHighIcon color='red' /> : editValue ? <EditIcon /> : <CheckIcon color='green' />}
					</IconContainer>
				}
			</TextFieldContainerInput>
			<Typography padding='5px 0 0 0' variant='regular' textAlign={ helperTextAlign || 'start' } color={error ? 'red' : 'lightGrey'}>{helperText}</Typography>
		</TextFieldContainer>
	);
};
export default (TextField);