import styled, { css } from 'styled-components';

export interface ProfileCardsContainerProps {
  padding?: string;
  disabled?: boolean;
}

export const ProfileCardsContainer = styled.div<ProfileCardsContainerProps>`
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.grey198};
    padding: ${props => props.padding};
    padding: 10px 10px 8px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    border-radius: 22px;
    cursor: pointer;
    min-height: 110px;
    flex: 1;
    ${props => props.disabled ? css`
      cursor: auto;
      border: none;
    ` : null}
`;