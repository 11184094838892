import styled, { keyframes } from 'styled-components';

export interface MiniToolTipContainerProps {
    content?: string;
}

export const PopperFade = keyframes`
  from {
   opacity: 0;
   margin-top: -15px;
    
  }
  to {
    opacity: 1;
   margin-top: 0px;
    
  }
`;


export interface BottomToolTipProps {
    width?: string;
    marginTop?: string;
}

export const BottomToolTip = styled.div <BottomToolTipProps>`
    opacity: 0;
    box-sizing: border-box;
    position: absolute;
    transition: display 0.3s ease;
    display:none;
    z-index: 100;
    top: 100%;
    padding-top: ${props => props.marginTop};
    width: ${props => props.width} !important;
    
`;
export const BottomToolTipContainer = styled.div <BottomToolTipProps>`
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px;
    border-radius: 6px;
    transition: display 0.3s ease;
    padding: 5px;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    background-color: ${props => props.theme.colors.toolTipGrey};
    
`;
export const MiniToolTipContainer = styled.div<MiniToolTipContainerProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;

    &:hover{
        ${BottomToolTip}{
            display: block;
            animation-name: ${PopperFade};
            animation-duration: 0.1s;
            animation-fill-mode: forwards;
        }
    }

   
`;

