import React from 'react';
import { ChevronRight } from '~/assets/icons';
import IconButton from '../IconButton';
type Props = {
    onClick?: VoidFunction
};
const ChevronRightIconButton : React.FC<Props> = ({ onClick } : Props) => {
	return (
		<IconButton size='25px' onClick={onClick} bgColor="primary">
			<ChevronRight style={{margin: '0 0 0 2px'}} color='white'></ChevronRight>
		</IconButton>
	);
};
export default (ChevronRightIconButton);