import styled from 'styled-components';

export interface Props {
    color?: string;
    disabled?: boolean;
  }

const ButtonStyled = styled.button<Props>`
  border-radius: 22px;
  display: flex;
  border: none;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 15px;
  box-sizing: border-box;
  color: ${props => props.theme.colors[props.disabled ? 'disabledFontGrey' : 'white']};
  background-color: ${props => props.theme.colors[props.disabled ? 'disabledGrey' : (props.color || 'primary')]};
  min-width: 160px;
  min-height: 56px;
  cursor: ${props => props.disabled ? null : 'pointer'};
`;
export default ButtonStyled;
