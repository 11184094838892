import React, { useContext, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/index';
import TopBar from '../TopBar';
import { Container, GeneralLayoutContainer, PayButtonContainer } from './styled';
import Alert from '../Alert';
import { AlertToastContext } from '~/context/alertToastContext';
import { CookiesContext } from '~/context/cookiesContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import { useCarrito, useGeneralLayout } from '~/hooks';
import SideBar from '../SideBar';
import CarritoDrawer from '../CarritoDrawer';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import BlackAlert from '../BlackAlert';
import Button from '../Button';
import _ from 'lodash';
import { getCookiesPreferences, pricePrettify, updateConsent } from '~/utils';
import GeneralLoading from '../GeneralLoading';
import { GoogleOAuthProvider } from '@react-oauth/google';
import WarningUserModal from '../WarningUserModal';
import CategoriaComplementoModal from '../CategoriaComplementoModal';
import { CheckoutContext } from '~/context/checkoutContext';
import NuevaOrdenModal from '../NuevaOrdenModal';
import Checkout from '~/views/checkout';
import CookiesBanner from '../CookiesBanner';

type Props = {
	children: JSX.Element | JSX.Element[],
};

const GeneralLayout : React.FC<Props> = ({ children } : Props) => {
	const {
		handleCloseAlert, 
		statusDialogProps, 
		statusBlackDialogProps, 
		handleCloseBlackAlert 
	} = useContext(AlertToastContext);

	const {
		nivelCheckoutId
	} = useContext(CheckoutContext);
	
	const {
		showBack,
		handleGoBack,
		nivelesTienda,
		backButtonContent,
		title,
		nivelesTiendaId,
		showSideBarIcon,
		xs,
		onlyTextfield,
		xsCarrito,
		setMainValue,
		responsiveHeight,
		complementoModalStatus,
		showTopbar,
		path,
		handleComplementoModalStatus,
		nuevaOrdenModalError,
		nuevaOrdenModalOpen,
		handleCloseNuevaOrdenModalOpen,
	} = useContext(GeneralLayoutContext);

	const {
		userInfo, 
		handleLogout,
		dataCarrito,
		initialLoading,
		token,
		openWarningUserModal,
	} = useContext(CookiesContext);

	const navigate = useNavigate();
	const {
		openSideBar,
		handleOpenSideBar,
		handleCloseSideBar,
		isBuscador,
		apiUrl,
	} = useGeneralLayout();

	const {
		openCarrito,
		handleCloseCarrito,
		handleOpenCarrito,
		handleModificaCarrito,
		handleCheckout,
		loadingVaciarCarrito,
		handleVaciarCarrito,
	} = useCarrito(undefined, true);

	const currentCarrito = _.find(dataCarrito, o=>o.nValor === nivelesTiendaId);

	const showGeneralLoading = (initialLoading || !token || initialLoading || !nivelesTienda);

	const showPayButton = ((showBack === null || showBack === '') && currentCarrito ) && nivelesTiendaId && !openCarrito;
	const isCheckout = path?.includes('/checkout/');
	// !(statusBlackDialogProps.open || statusDialogProps.open)

	const clientId = import.meta.env.VITE_APP_ID_GG;
	const tipoCheckout = import.meta.env.VITE_TIPO_CHECKOUT;


	/*
	 * GOOGLE TAG MANAGER - CONSENT MODE
	*/ 
	const [shouldShowBanner, setShouldShowBanner] = useState(false);
	const location = useLocation();

	const removeCookiesBanner = () => {
		setShouldShowBanner(false);
	};

	useEffect(() => {
		const cookiesPreferences = getCookiesPreferences();

		if (cookiesPreferences === null) {
			setShouldShowBanner(true);
			return;
		}

		updateConsent(cookiesPreferences);
	}, []);

	useEffect(() => {
		const cookiesPreferences = getCookiesPreferences();

		if (cookiesPreferences && !shouldShowBanner) {
			return;
		}

		if (location.pathname === '/privacidad') {
			setShouldShowBanner(false);
		} else {
			setShouldShowBanner(true);
		}
	}, [location.pathname]);

	return (
		<ThemeProvider theme={theme}>
			<GoogleOAuthProvider clientId={clientId}>
				<GeneralLoading showGeneralLoading={showGeneralLoading} />
				{
					token && <>
						<WarningUserModal openWarningUserModal={openWarningUserModal} />
						{
							showTopbar && <TopBar 
								xs={xs}
								setMainValue={setMainValue}
								onlyTextfield={onlyTextfield}
								nivelesTiendaId={isCheckout && nivelCheckoutId ? nivelCheckoutId : nivelesTiendaId}
								xsCarrito={xsCarrito}
								title={title}
								showSideBarIcon={showSideBarIcon}
								currentCarrito={currentCarrito}
								showBack={showBack}
								handleGoBack={handleGoBack}
								backButtonContent={backButtonContent}
								responsiveHeight={responsiveHeight}
								handleCloseSideBar={handleCloseSideBar} handleOpenSideBar={handleOpenSideBar} handleOpenCarrito={handleOpenCarrito} dataCarrito={dataCarrito} navigate={navigate} userInfo={userInfo}></TopBar>
						}

						<CarritoDrawer 
							loadingVaciarCarrito={loadingVaciarCarrito} 
							handleVaciarCarrito={handleVaciarCarrito} 
							nivelesTienda={nivelesTienda || []} 
							currentCarrito={currentCarrito} 
							handleCheckout={handleCheckout} 
							handleModificaCarrito={handleModificaCarrito} 
							apiUrl={apiUrl} 
							dataCarrito={dataCarrito} 
							handleClose={handleCloseCarrito} 
							open={openCarrito}
						/>

						<GeneralLayoutContainer responsiveHeight={responsiveHeight} showBack={showBack} showSideBarIcon={showSideBarIcon} initialLoading={showGeneralLoading}>
							{
								(showBack === '' || showBack === null || showSideBarIcon || isBuscador) && <SideBar 
									handleLogout={handleLogout}
									userInfo={userInfo}
									nivelesTienda={nivelesTienda || []}
									open={(openSideBar) && (showBack === '' || showBack === null || showSideBarIcon || isBuscador)}
									handleClose={handleCloseSideBar}
								></SideBar>
							}

							<Container showBack={showBack}>
								<div className='generalLayoutContainer'>
									{
										isCheckout && tipoCheckout === '2' ? <Checkout>{children}</Checkout> : children
									}
								</div>
							</Container>

						</GeneralLayoutContainer>
						{
							(showBack === null && !showGeneralLoading )&& <Footer></Footer>
						}

						{
							showPayButton ? <PayButtonContainer >
								<Button onClick={()=>handleCheckout(nivelesTiendaId)} minWidth='300px' variant='contained-round'>
									{`Pagar ${pricePrettify(currentCarrito.nTotal)}`}
								</Button>
							</PayButtonContainer> : null
						}

						<Alert bottom={ showPayButton ? '150px' :  undefined} handleClose={handleCloseAlert} error={statusDialogProps.error} subtitle={statusDialogProps.helperText} open={statusDialogProps.open}></Alert>

						<BlackAlert bottom={ showPayButton ? '150px' :  undefined} handleClose={handleCloseBlackAlert} open={statusBlackDialogProps.open} subtitle={statusBlackDialogProps.helperText} />

						<CategoriaComplementoModal complementoModalStatus={complementoModalStatus} handleClose={()=> handleComplementoModalStatus()} />

						<NuevaOrdenModal error={nuevaOrdenModalError} open={nuevaOrdenModalOpen} handleClose={handleCloseNuevaOrdenModalOpen} />
					</>
				}

				<CookiesBanner 
					shouldShowBanner={shouldShowBanner} 
					removeBanner={removeCookiesBanner} 
				/>
			</GoogleOAuthProvider>
		</ThemeProvider>
	);
};
export default (GeneralLayout);