import styled from 'styled-components';

export interface PropsCarouselSlot {
  img?: string | number;
}


export const ImgContainer = styled.div<PropsCarouselSlot>`
  width: 100%;
  align-items: center;
  height: 100%;
  gap: 15px;
  min-height: 50px;
  display: flex;
  border-radius: 5px;
  img{
    width: 100%;
  }

  overflow: hidden;

`;
export interface PropsCarouselContainer {
  maxwidth?: string;
}

export const CarouselContainer = styled.div<PropsCarouselContainer>`
  width: 100%;
  border-radius: 22px;
  overflow: hidden;
  max-height: 229px;
  align-items: center;
  display: flex;

  position: relative;
  @media (max-width: 1100px) {
    width: 100%;
    max-width: 100%;

  }
`;

export const IconButtonStyled = styled.button`
    aspect-ratio: 1;
    border-radius: 50%; 
    height: 40px;
    z-index: 2;
    width: 40px;
    display: grid;
    place-items: center;
    background: ${props => props.theme.colors.primary};
    border: none;
    position: relative;
    svg {
        position: absolute;
    }
    &:hover {
        cursor: pointer;
    }
`;
export const IconButtonContainerLeft = styled.div`
    position: absolute;
    bottom: 50%;
    top: 50%;
    left: 10px;
    transition: visibility 0.35s;
    z-index: 2;
    
    visibility: visible;
    opacity: 0.9;
      
`;
export const IconButtonContainerRight = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    transition: visibility 0.35s;
    bottom: 50%;
    z-index: 2;
    top: 50%;
    visibility: visible;
    opacity: 0.9;
      
`;



export const IconButton = styled.div`
    &:hover {
        cursor: pointer;
    }
    padding: 1px;
      
`;

