import styled from 'styled-components';
import LinkSpan from '../LinkSpan';
import Typography from '../Typography';

export interface FooterContainerProps {
  padding?: string;
}

export const FooterContainer = styled.footer<FooterContainerProps>`
    background-color: ${props => props.theme.colors.white251};
    padding: 5% 0 5% 0;
    margin-top: auto;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    .border-left {
        padding: 0 30px 20px 30px;
        border-left: 1px solid ${props => props.theme.colors.borderLightGrey};
    }
    .border-right {
        padding: 30px 30px 20px 30px;
        border-right: 1px solid ${props => props.theme.colors.borderLightGrey};
    }
    .center {
        padding: 20px 30px 20px 30px;
    }
    .item {
        display: flex;
        flex-direction: column;
        flex: 1;
        box-sizing: border-box;
    }

    .pd-l-60{
        @media only screen and (min-width: 768px) {
            grid-area: d;
        }
        @media only screen and (min-width: 992px) {
            padding-right:60px;
            grid-area: unset;
        }
    }
`;

export const UpperGrid = styled.div`
        box-sizing: border-box;
        display: grid;
        gap:5%;
        place-items:center;
        text-align:center;

    @media only screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas:
        "a b c"
        "d d d";
        place-items:unset;
        text-align:unset;
    }

    @media only screen and (min-width: 992px) {
        grid-template-columns: 2fr 1fr 1fr 2fr;
        place-items:unset;
        text-align:unset;
    }


`;

export const LowerGrid = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 2fr 1fr;
    border-top: 1px solid ${props => props.theme.colors.borderLightGrey};

`;

export const StyledTypo = styled(Typography)`
    line-height:1.5;
`;

export const StyledLinkSpan = styled(LinkSpan)`
    text-decoration:underline;
`;

export const ContainerIcon = styled.div`
    cursor: pointer;
`;