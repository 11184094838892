import { useState, useRef } from 'react';

const useToolTipSelect = () => {
	// Maneja el estado de los selects
	const [showTooltip, setShowTooltip] = useState(false);
	const button = useRef<HTMLInputElement>(null);

	const onMouseEnter = () => {
		setShowTooltip(!showTooltip);
	};
	const onMouseLeave = () => {
		setShowTooltip(false);
	};


	return {
		onMouseEnter,
		onMouseLeave,
		showTooltip,
		button,
	};
};

export default useToolTipSelect;