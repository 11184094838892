import styled from 'styled-components';

export interface DirectionCardContainerProps {
    view?: boolean;
    marginBottom?: string;
    predeterminada?: boolean | null;
}
  

export const DirectionCardContainer = styled.div<DirectionCardContainerProps>`
    .iconContainer {
        background-color: ${props => props.theme.colors.borderLightGrey};
        aspect-ratio: 1;
        border-radius: 50%; 
        padding: 2px;
        width: 38px;
        display: flex;
        align-items: center;  
        justify-content: center;
        height: 38px;
    }
    padding: 10px;
    display: flex;
    min-height: 56px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${props => props.marginBottom || ('10px')};
    cursor: ${props => props.view ?  null : 'pointer'};
    border-radius: 4px;
    border: ${props => `1px solid ${props.theme.colors.borderLightGrey}`}

`;

