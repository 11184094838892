import styled, {css, keyframes} from 'styled-components';
import colors from '~/styles/colors';

const colorFade = keyframes`
from {
  background-color: ${colors.primary};
  
}
to {
  background-color: ${colors.primaryHover};

}
`;

const colorFadeReverse = keyframes`
from {
  background-color: ${colors.white};
  
}
to {
  background-color: ${colors.blackT};

}
`;
export interface IconButtonStyledProps {
  active?: boolean;
  reverse?: boolean;
  disabled?: boolean;
}


export const IconButtonStyled = styled.button<IconButtonStyledProps>`
    aspect-ratio: 1;
    border-radius: 50%; 
    padding: 2px;
    display: grid;
    place-items: center;
    background-color: transparent;
    border: none;
    text-align: center;
    overflow: hidden;
    width: 35px;
    height: 35px;
    border: 2.4px solid ${props => props.theme.colors[props.reverse ? 'primary' : 'white']};
    background-color: ${props => props.theme.colors[props.reverse ? 'white' : 'primary']};
    
    svg{
        position: absolute;
    }
  

    ${props => props.disabled ? css`
      background-color: ${props.theme.colors.grey};
   
    ` : css`
        cursor: pointer;
    
        .hover {
          opacity: 0;
        }

        ${props.active ? css`
      animation-name: ${colorFade};
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
   
    ` : css`
      &:hover{
        animation-name: ${props.reverse? colorFadeReverse : colorFade};
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        
      }
    `}
    `}
    
    
`;
