import styled from 'styled-components';

export interface HelpCardContainerProps {
  margin?: string;
}

export const HelpCardContainer = styled.div<HelpCardContainerProps>`
    display: flex;
    min-height: 56px;
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    margin: ${props => props.margin};
`;