import React from 'react';
import Grid from '../Grid';
import { ChevronUpStyled, ShowContainer } from './styled';
import { ExpandLessIcon } from '~/assets/icons';
type Props = {
  children?: JSX.Element | JSX.Element[],
  hideChevron?: boolean,
  bordertop?: boolean,
  margin?: string,
  handleOpen: () => void,
  open: boolean
};
const ExpansionPanelBottom : React.FC<Props> = ({ children, hideChevron, bordertop, margin, handleOpen, open } : Props) => {

	return (
		<>
			<ShowContainer isShowing={open}>
				{children}
			</ShowContainer>
			{
				!hideChevron && <Grid margin={margin} bordertop={bordertop} className='pointer' height='40px' onClick={handleOpen} container justifyContent='center' alignItems='center'>
					<ChevronUpStyled isShowing={!open}>
						<ExpandLessIcon color='primary' />
					</ChevronUpStyled>
				</Grid>
			}
			
		</>
	);
};
export default (ExpansionPanelBottom);