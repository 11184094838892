import styled from 'styled-components';

export interface ILoadingContainer {
  minWidth?: string;
  fullHeight?: boolean;
  marginTop?: string
}

export const LoadingContainer = styled.div<ILoadingContainer>`
    display: flex;
    justify-content: center;
    min-width: ${props => props.minWidth};
    margin-top: ${props => props.marginTop || '45px'};
    .spinner-10 {
        width: 40px;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 8px solid ${props => props.theme.colors.primary};
        animation:
            s10-1 0.8s infinite linear alternate,
            s10-2 1.6s infinite linear;
    }
    @keyframes s10-1{
        0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
        12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
        25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
        50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
        62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
        75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
        100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
    }
    @keyframes s10-2{ 
        0%    {transform:scaleY(1)  rotate(0deg)}
        49.99%{transform:scaleY(1)  rotate(135deg)}
        50%   {transform:scaleY(-1) rotate(0deg)}
        100%  {transform:scaleY(-1) rotate(-135deg)}
    }
`;