import styled from 'styled-components';
import Grid from '../Grid';

export const BannerContainer = styled(Grid)`
    border-radius: 15px;
    max-height: 229px;
    overflow: hidden;
    img {
        border-radius: 15px;
    }

`;