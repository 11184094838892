import styled from 'styled-components';
import Grid from '../Grid';
import { NivelTabProps } from '../NivelesAppBar/styled';

export interface ItemTabProps {
  active?: boolean;
}
// ${props => props.borderbottom ? `1px solid ${props.theme.colors.borderLightGrey}` : null};
export const ItemTab = styled(Grid)<ItemTabProps>`
    border-bottom: ${props => props.active ? `4px solid ${props.theme.colors.primary}` : `1px solid ${props.theme.colors.borderLightGrey}`};
    &:hover {
        cursor: pointer;
    }
    transition: border-bottom 0.3s ease-in-out;
    position: relative;
`;

export const ActiveDivider = styled.div<NivelTabProps>`
  background-color: ${props => props.theme.colors[props.active ? 'primary' : 'transparent']};
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  z-index: 2;

`;