import { useState } from 'react';

const useDialog = (defaultValue?: boolean) => {
	const [open, setOpen] = useState(defaultValue || false);

	const handleOpen = () => {
		setOpen(!open);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleShow = () => {
		setOpen(true);
	};


	return {
		open,
		handleOpen,
		handleClose,
		handleShow,
		setOpen
	};
};

export default useDialog;