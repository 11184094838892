import React from 'react';
import Typography from '../Typography';
import { TextFieldLabel } from '../TextField/styled';
import { MenuItem, MenuItemsContainer, SvgContainer, TextFieldContainerInput, SelectContainer, Popover } from './styled';
import { useEdit, useToolTipSelect } from '~/hooks';
import { ExpandMoreIcon } from '~/assets/icons';
import Grid from '../Grid';
import { SelectItemsTypes } from '~/interfaces';

type Props = {
	label?: string,
	value?: SelectItemsTypes,
	padding?: string,
	check?: boolean,
	helperText?: string,
	error?: boolean,
	items: SelectItemsTypes[],
	edit?: boolean,
	fullWidth?: boolean,
	id: string,
	onChange: (value: SelectItemsTypes) => void,
	disabled?: boolean
};
const TextFieldSelect : React.FC<Props> = ({ label, value, onChange, error, items, check, id, padding, helperText, edit, disabled, fullWidth } : Props) => {
	const {
		editValue,
		handleEditOnChange
	} = useEdit(onChange, edit);
	
	const {
		onMouseEnter,
		onMouseLeave,
		showTooltip,
		button,
	} = useToolTipSelect();

	
	window.addEventListener('click', function(e: any){   
		if (!(document && document?.getElementById(id)?.contains(e.target))){
			onMouseLeave();

		}
	});

	return <Grid maxwidth={fullWidth ? '100%' : '358px'} padding={padding || '0 0 5px 0'} flex item container direction='column'>
		<Grid item padding='0 0 4px 0'>
			<TextFieldLabel>{label}</TextFieldLabel>
		</Grid>
		<SelectContainer id={id}>
			<TextFieldContainerInput disabled={disabled} open={showTooltip} className="button" onClick={disabled ? undefined : onMouseEnter} ref={button} error={error} check={check && !editValue}>
				<Typography color={disabled ? 'disabledTextFieldGrey' : 'textFieldLabel'} className='noselect'>{value?.cValor || 'Selecciona'}</Typography>
				<SvgContainer>
					<ExpandMoreIcon></ExpandMoreIcon> 
				</SvgContainer>
			</TextFieldContainerInput>
			<Popover open={showTooltip}>
			
				<MenuItemsContainer>
					{items?.map((item, i) => (
						<MenuItem className='noselect' onClick={()=>{
							if(handleEditOnChange){
								handleEditOnChange(item);
							} else {
								onChange(item);
							}
							onMouseLeave();
						}
						} key={i}>
							{item.cValor}
						</MenuItem>
					))}
				</MenuItemsContainer>
			</Popover>
		</SelectContainer>
		{
			helperText && <Typography padding='5px 0 0 0' variant='regular' color={error ? 'red' : 'lightGrey'}>{helperText}</Typography>
		}
	</Grid>;
};
export default (TextFieldSelect);