export const keyValidation = (e: React.KeyboardEvent<HTMLInputElement>) => {
	const key = e.keyCode || e.which;
	const teclado = String.fromCharCode(key).toLowerCase();
	const validos = '0123456789';
	if (validos.indexOf(teclado) === -1) {
		e.preventDefault();
	}
};

export const keyValidationNumbersAndAlphabet = (e: React.KeyboardEvent<HTMLInputElement>) => {
	const key = e.keyCode || e.which;
	const teclado = String.fromCharCode(key).toLowerCase();
	const validos = '0123456789abcdefghijklmnñopqrstuvwxyz';
	if (validos.indexOf(teclado) === -1) {
		e.preventDefault();
	}
};


export interface IDictionary<TValue> {
  [value: string]: TValue;
}

export const keyPress = (e: React.KeyboardEvent<HTMLInputElement>, i: number, pinValue: IDictionary<string>) => {
	if ((e.key === '46' || e.key === '8') && (pinValue[`pin${i + 1}`] === '')) {
		if (i !== 0 ){
			e.preventDefault();
			const input = document.getElementById(`pin${i}`);
			input?.focus();
		}
	} else if (e.key !== '46' && e.key !== '8') {
		keyValidation(e);
	}
};