import React from 'react';
import { CatalogosTiposDatosProps } from '~/interfaces';
import Grid from '../Grid';
import Typography from '../Typography';
import { ToggleButtonContainer, ToggleItemContainer } from './styled';
type Props = {
  activeButtonId: number | null,
  toggleItems: CatalogosTiposDatosProps[],
  handleToggle: (id: number, item: CatalogosTiposDatosProps) => void,
};
const ToggleButton : React.FC<Props> = ({ toggleItems, activeButtonId, handleToggle } : Props) => {
    
	return (
		<ToggleButtonContainer>
			<div className='circularContainer'>
				<ToggleItemContainer width={(activeButtonId || 1) * 171}>
					<div className='item'></div>
				</ToggleItemContainer>
				{
					toggleItems.map((item, i) => <Grid className='itemText' width='171px' item flex onClick={() => handleToggle(item.nValor, item)}  key={i}>
						<Typography color='black' variant='body2'>{item.cValor}</Typography>
					</Grid>)
				}
			</div>
		</ToggleButtonContainer>
	);
};
export default (ToggleButton);