import styled from 'styled-components';
import Grid from '../Grid';

export interface GridStyledProps {
  padding?: string;
}

export const GridStyled = styled(Grid) <GridStyledProps>`
    border: 1px solid ${props => props.theme.colors.grey198 };
    border-radius:18px;
    cursor: pointer;

`;