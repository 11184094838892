import styled, { css } from 'styled-components';

export interface Props {
    color?: string;
    variant?: string;
    disabled?: boolean;
    circular?: boolean;
    padding?: string;
    spacing?: string;
    margin?: string;
    fontSize?: string;
  }

const ButtonStyled = styled.button<Props>`

  box-sizing: border-box;
  border-radius: ${props => props.circular ? '30px' : '5px'};
  transition: background-color 0.3 ease;
  color: ${props => props.theme.colors[props.disabled ? 'disabledGrey' : (props.color || 'primary')]};
  text-align: center;
  min-height: ${props => props.padding ? null : '52px'};
  margin: ${props => props.margin};
  padding: ${props => props.padding ? props.padding : '7px 12px 7px 12px'};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${props => props.spacing};
  width: 100%;

  font-family: ${props =>props.theme.text.font.primary};
  font-size: ${props => props.theme.text.size[props.fontSize || 'subtitle1']};


  ${props => {
		switch (props.variant) {
		case 'contained':
			return css`
        border: none;
        color: ${props.theme.colors[props.disabled ? 'disabledFontGrey' : 'white']};

        background-color: ${props.theme.colors[props.disabled ? 'disabledGrey' : (props.color || 'primary')]};
        `;
		case 'outlined':
			return `
        border: 2.4px solid ${props.theme.colors[props.disabled ? 'disabledGrey' : (props.color || 'primary')]};
        background-color: ${props.theme.colors.white};
      `;
		case 'accept':
			return css`
        border: none;
        color: ${props.theme.colors[props.disabled ? 'disabledFontGrey' : 'white']};
        background-color: ${props.theme.colors[props.disabled ? 'disabledGrey' : ('primary')]};
        width: auto;
        min-width: 200px;
        margin-top: 10px;

      `;
		case 'mini':
			return css`
        border: none;
        width: auto;
        margin-top: 1px;
        padding: ${props.padding ? props.padding : '5px'}
        min-height: auto;
        background-color: transparent;
        color: ${props.theme.colors.black};
        font-size: ${props =>props.theme.text.size.caption};

      `;

		case 'contained-round':
			return css`
        padding: ${props.padding ? props.padding : '10px'};
        border: none;
        color: ${props.theme.colors[props.disabled ? 'disabledFontGrey' : 'white']};
        border-radius: 22px;
        background-color: ${props.theme.colors[props.disabled ? 'disabledGrey' : (props.color || 'primary')]};
        min-height: 56px;

        `;
		case 'outlined-round':
			return css`
        min-height: 56px;
        border-radius: 22px;
        padding: ${props.padding ? props.padding : '10px'};
        border: 2.4px solid ${props.theme.colors[props.disabled ? 'disabledGrey' : (props.color || 'primary')]};
        background-color: ${props.theme.colors.white};
        box-sizing: border-box;
      `;
		case 'underline':
			return css`
        text-decoration: underline;
        text-decoration-color: ${props.theme.colors[props.disabled ? 'grey' : 'primary']};
        border: none;
        background-color: transparent;
        color: ${props.theme.colors[props.disabled ? 'grey' : (props.color || 'primary')]};

        `;
		case 'underline-subtitle2':
			return css`
        text-decoration: underline;
        text-decoration-color: ${props.theme.colors[props.disabled ? 'grey' : 'primary']};
        border: none;
        background-color: transparent;
        color: ${props.theme.colors[props.disabled ? 'grey' : (props.color || 'primary')]};
        font-size: ${props =>props.theme.text.size.subtitle2};

        `;
    
		default:
			return `
        border: none;
        background-color: transparent;
        `;
		}
	}}

  font-weight: ${props =>props.theme.text.weight.medium};
  cursor: ${props => props.disabled ? null: 'pointer'};



`;
export default ButtonStyled;
