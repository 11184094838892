import React from 'react';
import { Circle, Cupon } from './styled';
type Props = {
  children: JSX.Element | JSX.Element[],
  margin?: string,
  onClick?: VoidFunction,
  color?: string,
  disabled?: boolean
};
const CuponContainer : React.FC<Props> = ({ children, margin, onClick, disabled, color} : Props) => {
	return (
		<Cupon color={color} className='noselect' disabled={disabled} onClick={onClick} margin={margin}>
			<div className='container'>
				<Circle className='right'/>
				{children}
				<Circle className='left' />

			</div>

		</Cupon>
	);
};
export default (CuponContainer);