import {
	useState,
} from 'react';

const useWizard = (defaultStep?: number) => {
	// Maneja el estado de los Wizard y Carrouseles
	const [activePosition, setActivePosition] = useState(defaultStep || 0);
	const handleNext = () => {
		setActivePosition(activePosition + 1);
	};
	const handleBack = () => {
		setActivePosition(activePosition - 1);
	
	};
	const handleActivePosition = (value: number) => {
		setActivePosition(value);
	};
	return {
		activePosition,
		handleNext,
		handleBack,
		handleActivePosition,
	};
};
export default (useWizard);