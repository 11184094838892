import styled, {keyframes, css} from 'styled-components';
import colors from '~/styles/colors';
import { TextFieldToolTipFade } from '~/styles/keyframes';

export const PopperFade = keyframes`
  from {
   opacity: 0;
   margin-top: -15px;
    
  }
  to {
    opacity: 1;
   margin-top: 10px;
    
  }
`;

export interface TextFieldProps {
    open?: boolean;
    icon?: boolean;
    error?: boolean;
    check?: boolean;
    disabled?: boolean
  }

export const SvgContainer = styled.div`
  position: relative;
  height: 24px;
`;




export const TextFieldContainerInput = styled.div<TextFieldProps>`
    background-color: ${props => props.theme.colors[props.disabled ? 'inputGrey' : 'white']};
    min-height: 52px;
    border-radius: 4px;
    min-width: 100px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 10px 12px 10px 12px;
    justify-content: space-between;
    cursor: pointer;
    ${props => `
      border: ${props.error || props.check ? '2px' : '1px'} solid ${colors[props.error ? 'red' : props.check ? 'green' : 'grey']};
    `};
    
    ${SvgContainer}{
            transition: transform .2s ease;
            transform: rotate(0deg);
    }
    ${props => props.open ? css`
    ${SvgContainer}{
      transform: rotate(-180deg);
    }
    ` : null}
`;
// white-space: nowrap;

export const MenuItem = styled.div`
    padding: 10px;
    border-bottom: 1px solid ${props => props.theme.colors.borderLightGrey};
    font-family: ${props => props.theme.text.font.primary};
    font-weight: ${props => props.theme.text.weight.regular};
    cursor: pointer;
    color: ${props => props.theme.colors.black};
    transition: background-color .3s ease;
    &:hover {
        background-color: ${props => props.theme.colors.menuItemBg};
    }
`;

interface MenuItemsContainerProps  {
  height?: string;
  padding?: string;

}
export const MenuItemsContainer = styled.div<MenuItemsContainerProps>`
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.darkGrey65};
    font-size: ${props => props.theme.text.size.subtitle1};
    height: ${props => props.height};
    padding: ${props => props.padding};
    box-sizing: border-box;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
        
    ::-webkit-scrollbar:vertical {
        width: 5px;
    }
        
    ::-webkit-scrollbar:horizontal {
        height: 12px !important;
    }
        
    ::-webkit-scrollbar-thumb {
        background-color: #219EEC;
        border-radius: 0px;
        border: 0px solid ${props => props.theme.colors.white};

    }
        
    ::-webkit-scrollbar-track {
        border-radius: 0px;
        background-color: ${props => props.theme.colors.inputGrey};
    }
`;


//-----

export interface PopoverProps {
  open?: boolean;
  tooltipwidth?: string;
  zIndex?: number;
}

export const SelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

`;

export const Popover = styled.div<PopoverProps>`
    background-color: ${props=>props.theme.colors.white};
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px;
    opacity: 0;
    border-radius: 6px;
    box-sizing: border-box;
    position: absolute;
    transition: display 0.3s ease;
    z-index: ${props => props.zIndex || '1000'};
    width: ${props=>props.tooltipwidth};
    ${props => props.open ? css`
    animation-name: ${TextFieldToolTipFade};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    display:block;

    ` : css`
      display: none;
    `}
    top: 100%;
    width: 100%;
`;

