import React from 'react';
import Modal from '../Modal';
import { ModalContainer } from '../CategoriaComplementoModal/styled';
import CloseButton from '../CloseButton';
import CardMedia from '../CardMedia';
import { getCdnUrl } from '~/utils';
import { useInfoModal } from '~/hooks';
import Grid from '../Grid';

type Props = {
	bEnCheckOut?: boolean,
	nNivelTienda?: number,
};

const InfoModal : React.FC<Props> = ({ bEnCheckOut, nNivelTienda } : Props) => {
	const {
		handleClose,
		open,
		infoModalData,
		handleClick
	} = useInfoModal(bEnCheckOut, nNivelTienda);


	return (
		<Modal
			width={'100%'}
			maxWidth={'341px'}
			open={open}
			handleClose={handleClose}
		>
			<ModalContainer>
				<div className='header'><CloseButton color='white' onClick={handleClose} /></div>
				{
					infoModalData.cImagenUrl && (
						<CardMedia 
							onClick={handleClick} 
							pointer={!!(infoModalData.cUrlClick)} 
							height='335px' 
							borderRadius='22px' 
							maxwidth='100%' 
							alt='Info Modal'
							src={getCdnUrl(infoModalData.cImagenUrl)} 
						/>
					)
				}
			</ModalContainer>
			
			<Grid padding='5px 5px 0px 5px'></Grid>
		</Modal>
	);
};
export default (InfoModal);