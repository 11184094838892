import styled from 'styled-components';

export interface DividerProps {
    margin?: string;
  }
  
export const Divider = styled.div`
    height: 1px !important;
    background-color: ${props => props.theme.colors.borderGrey};
    opacity: 0.5;
    flex: 1;
`;

export const PanaDividerContainer = styled.div<DividerProps>`
    display: flex;
    margin: ${props => props.margin};
    position: relative;
    height: 42px;
    align-items: center;
    .imageContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }

    .circularContainer {
      aspect-ratio: 1;
      border-radius: 50%; 
      padding: 2px;
      display: grid;
      place-items: center;
      background-color: ${props => props.theme.colors.white};
      border: 1px solid ${props => props.theme.colors.borderGrey};
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      width: 41px;
      height: 41px;
    }
`;
