import Cookies from 'js-cookie';
import React, { useState, useEffect, createContext, } from 'react';
import { ContextProps, DataCarritoTypes, DetalleEnvioType, TiendaContextProps, UserInfoType } from '~/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { TIPO_USUARIO_COMPLETADO } from '~/constants';

export const CookiesContext = createContext({} as TiendaContextProps);

// Se manejan estados globales de la info de usuario
export const CookiesProvider = ({children}: ContextProps) => {
	const location = useLocation();
	const path = location.pathname;
	
	const cookiesToken = Cookies.get('token');
	const cookiesmacAddress = Cookies.get('macAddress');
	const cookiesUserInfo = Cookies.get('userInfo');
	const cookiesDetalledEnvio = Cookies.get('detalleEnvio');
	const [yPosition, setyPosition] = useState<number | null>(null);

	// Paginador de productos
	const [active, setActive] = useState(1);
	const handleactive = (value: number) => {
		setActive(value);
	};
	const handleNext = () => {
		setActive(active + 1);
	};
	const handleBack = () => {
		setActive(active - 1);
	};
	const [menuId, setMenuId] = useState<number | undefined>(undefined);
	

	const [currentCategoria, setCurrentCategoria] = useState<string | undefined>('');



	const [token, settoken] = useState<null | string>(cookiesToken || null);
	const [macAddress, setMacAddress] = useState<null | string>(cookiesmacAddress || null);
	const [userInfo, setUserInfo] = useState<null | UserInfoType>(cookiesUserInfo ? JSON.parse(cookiesUserInfo) : null );

	const [detalleEnvio, setDetalledEnvio] = useState<null | DetalleEnvioType>(cookiesDetalledEnvio ? JSON.parse(cookiesDetalledEnvio) : null );

	const handleDetalleEnvio = (value: DetalleEnvioType | null) => {
		if(value){
			setDetalledEnvio(value);
			Cookies.set('detalleEnvio', JSON.stringify(value));
		} else {
			setDetalledEnvio(null);
			Cookies.remove('detalleEnvio');
		}
	};


	const apiUrl = import.meta.env.VITE_API_URL;
	const secretKeyToken = import.meta.env.VITE_SECRET_KEY_TOKEN;

	useEffect(() => {
		if(path.includes('/tienda/')){
			const pathSplit = path.split('/');
			if(pathSplit[2]){
				setMenuId(parseInt(pathSplit[2]));
			}
		} else if(path === '/'){
			setMenuId(undefined);
		}
		// Para que cada que cambie de ruta, te lleve para arriba
		window.scrollTo(0, 0);

	}, [path]);

	useEffect(() => {
		const appId = import.meta.env.VITE_APP_ID_FB;

		window?.FB?.init({
			appId            : appId,
			autoLogAppEvents : true,
			xfbml            : true,
			version          : 'v16.0'
		});

		if(cookiesToken){
			settoken(cookiesToken);
		}
		if(cookiesmacAddress){
			setMacAddress(cookiesmacAddress);
				
			if(!token && cookiesmacAddress){
			
				handleLogin(undefined, cookiesmacAddress);
			}

			if(cookiesUserInfo){
				const newUserInfo: UserInfoType = JSON.parse(cookiesUserInfo);
				if(newUserInfo.nEstatus === 6  && cookiesmacAddress){
					handleLogin(undefined, cookiesmacAddress);
	
				} else {
					setUserInfo(newUserInfo);
				}
			}
	
		} else {

			fetch(`${apiUrl}/WEB/ObtenerIdentificador`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${secretKeyToken}`
				}
			}).then((res) => res.json()).then((response) => {
	
				if(response && response.Result && response.Result.token){
					const macAddressToken = response.Result.token;
					setMacAddress(macAddressToken);
					Cookies.set('macAddress', macAddressToken);

					handleLogin(undefined, macAddressToken);

				}
	
			}).catch((error) => {
				console.error(error, 'error');
			});

		}
		
	
		
	}, []);




	const handleUserInfo = (tokenValue: string,macAddressValue: string,userInfoValue: UserInfoType) => {
		settoken(tokenValue);
		setMacAddress(macAddressValue);
		setUserInfo({...userInfo,  ...userInfoValue});
		Cookies.set('token', tokenValue);
		Cookies.set('macAddress', macAddressValue);
		Cookies.set('userInfo', JSON.stringify({...userInfo,  ...userInfoValue}));

		if(userInfoValue?.nEstatus !== TIPO_USUARIO_COMPLETADO && !userInfoValue.invitado){
			handleOpenWarningUserModal(true);
		} else {
			handleOpenWarningUserModal(false);
		}

	};

	
	const handleActiveStatus = (newStatus?: number) => {
		const copy = {...userInfo, nEstatus: newStatus || 2};
		setUserInfo(copy);
		Cookies.set('userInfo', JSON.stringify(copy));

	};
	const headersApi = {
		'Authorization': token ? `Bearer ${token}` : '',
		'macAddress': macAddress || '',
		'Content-Type': 'application/json',
	};

	const headersApiKey = {
		'Authorization': secretKeyToken ? `Bearer ${secretKeyToken}` : '',
		'macAddress': macAddress || '',
		'Content-Type': 'application/json',
	};


	const handleMousePos = () => {
		setyPosition(window.scrollY);
	};

	const [openWarningUserModal, setOpenWarningUserModal] = useState(false);
	const handleOpenWarningUserModal = (value: boolean) => {
		setOpenWarningUserModal(value);
	};

	
	// Función para iniciar sesión
	const handleLogin = (onFinish?: VoidFunction, macAddressNew?: string) => {
		if(macAddressNew || macAddress){
			const body = {
				cCorreo: '',
				cContrasenia: '',
				nTipoDispositivo: 3,
				nTipoInicioSesion: 2,
				cMac_Address: macAddressNew || macAddress,
			};
			
			fetch(`${apiUrl}/APPWEB/Login`, {
				body: JSON.stringify(body),
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				}
			}).then((res) => res.json()).then((responseLogin) => {
				if(responseLogin.Result?.done && !responseLogin.Result?.error){
					const estatus = responseLogin.Result.nEstatus;
					handleUserInfo(responseLogin.Result.token, (macAddressNew || macAddress || ''), {
						cNombre: responseLogin.Result.cNombre,
						cApellido_Paterno: responseLogin.Result.cApellido_Paterno,
						cApellido_Materno: responseLogin.Result.cApellido_Materno,
						nGenero: responseLogin.Result.nGenero,
						cCorreo: responseLogin.Result.cCorreo,
						cNumero_Celular: responseLogin.Result.cNumero_Celular,
						nEstatus: estatus,
						dFecha_Nacimiento: responseLogin.Result.dFecha_Nacimiento,
						cImg_Perfil: responseLogin.Result.cImg_Perfil,
						invitado: true,
					});

					if(onFinish) {
						onFinish();
					}
	
				} 
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	};
	const navigate = useNavigate();

	useEffect(() => {
		if(token && macAddress){
			fetch(`${apiUrl}/APPWEB/RevisarSesion`, {
				method: 'GET',
				headers: headersApi
			}).then((res) => res.json()).then((response) => {
	
				if(response?.Result && response.Result.done){
					const result = response.Result;

					if(result.nEstatus === 6){
						navigate('/inicia-sesion');

					} else {
						handleUserInfo(token, ( macAddress || ''), {
							cNombre: result.cNombre,
							cApellido_Paterno: result.cApellido_Paterno,
							cApellido_Materno: result.cApellido_Materno,
							nGenero: result.nGenero,
							cCorreo: result.cCorreo,
							cNumero_Celular: result.cNumero_Celular,
							nEstatus: result.nEstatus,
							dFecha_Nacimiento: result.dFecha_Nacimiento,
							cImg_Perfil: result.cImg_Perfil,
							invitado: result.nTipoUsuario === 2,
						});

					}
					
	
				} else {
					handleLogin(undefined, macAddress);
				}
	
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	}, [token === null]);


	// carrito

	const [dataCarrito, setDataCarrito] = useState<undefined | DataCarritoTypes[]>(undefined);

	const handleLogout = (onFinish?: VoidFunction) => {
		handleLogin(onFinish);

	};
	const [initialLoading, setInitialLoading] = useState(true);
	const handleDoneInitialLoading = () => {
		setInitialLoading(false);
	};
	const tipoCheckout = import.meta.env.VITE_TIPO_CHECKOUT;

	useEffect(() => {
		if(tipoCheckout === '1'){
			const scriptElement = document.getElementById('mercadoPagoScript');
			if(scriptElement){
				scriptElement?.parentNode?.removeChild(scriptElement);
			}
		}
	}, [(tipoCheckout === '1' ? path : undefined)]);
	
	return (
		<CookiesContext.Provider
			value={{
				token,
				macAddress,
				userInfo,
				setCurrentCategoria,
				setyPosition,
				handleUserInfo, 
				headersApi,
				headersApiKey,
				handleActiveStatus,
				handleLogout,
				yPosition,
				active,
				setActive,
				handleactive,
				handleNext,
				handleBack,
				currentCategoria,
				menuId,
				setMenuId,
				dataCarrito,
				handleMousePos,
				setDataCarrito,
				detalleEnvio,
				handleDetalleEnvio,
				initialLoading,
				handleDoneInitialLoading,
				openWarningUserModal,
				handleOpenWarningUserModal,
			}}
		>
			{children}
		</CookiesContext.Provider>
	);
};

export default (CookiesProvider);
