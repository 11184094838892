import styled from 'styled-components';

export interface PagesProps {
  active?: boolean;
  disabled?: boolean;
}

export const PaginatorContainer = styled.div`
    display: flex;
    height: 35px;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
    overflow: hidden;
`;

export const Pages = styled.div<PagesProps>`
    aspect-ratio: 1;
    border-radius: 50%; 
    display: grid;
    height: 34px;
    width: 34px;
    place-items: center;
    border: none;
    text-align: center;
    overflow: hidden;
    background-color: ${props => props.theme.colors[props.active ? 'primary' : 'primary80']};
    color: ${props => props.theme.colors.white};
    font-weight: ${props =>props.theme.text.weight.bold};
    font-size: ${props =>props.theme.text.size.body1};
    font-family: ${props =>props.theme.text.font.primary};
    ${props => props.disabled ? `
    background-color: ${props.theme.colors.disabledGrey};
    height: 34px;
    width: 34px;
    ` : null}

`;

export const PagesMore = styled.div<PagesProps>`
    aspect-ratio: 1;
    border-radius: 50%; 
    display: grid;
    height: 34px;
    width: 34px;
    place-items: center;
    background-color: transparent;
    border: none;
    text-align: center;
    overflow: hidden;
    color: ${props => props.theme.colors.pink};
    font-weight: ${props =>props.theme.text.weight.bold};
    font-size: ${props =>props.theme.text.size.caption};
    font-family: ${props =>props.theme.text.font.primary};

`;