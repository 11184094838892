import styled from 'styled-components';

export interface MenuItemChevronContainerProps {
    padding?: string;
}

export const MenuItemChevronContainer = styled.div<MenuItemChevronContainerProps>`
    display: flex;
    align-items: center;
    padding: ${props => props.padding};
    cursor: pointer;
`;
