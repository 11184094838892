import styled from 'styled-components';
export interface AlertContainerProps {
  open?: boolean;
  bottom?: string;
}
export const AlertContainer = styled.div<AlertContainerProps>`

  border-radius: 6px;
  position: fixed; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  box-sizing: border-box;
  
  width: 0px;
  bottom: ${props => props.bottom || '50px'};
  z-index: 1000000000;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  align-items: center;
  transition: all 0.3s ease;
  
  ${props => props.open ? `
    width: 250px;
    transition: all 0.3s ease;
    overflow: visible;
    opacity: 1;

    div {

      visibility: visible;
      
    }
  ` : null}
`;
export interface GreenContainerProps {
  error?: boolean;
}
export const GreenContainer = styled.div<GreenContainerProps>`
  background-color: ${props => props.error ? props.theme.colors.red : props.theme.colors.green};
  padding: 12px 10px 10px 10px;
  border-radius: 6px 0 0 6px;
  display: flex;
  z-index: 21;
  overflow: hidden;
  box-sizing: border-box;

  align-items: center;
  transition: all 0.3s ease;
  color: ${props => props.theme.colors.white} !important;

  svg {
    color: ${props => props.theme.colors.white} !important;
  }


`;

export const ShadowContainer = styled.div`
  display: flex;
  gap: 3px;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.white};
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  border-radius: 6px;
  width: 250px;

`;

export const BodyContainer = styled.div`
  padding: 0 2px 0 5px;
  display: flex;
  border-radius: 0 6px 6px 0;
  box-sizing: border-box;
  transition: all 0.3s ease;
  overflow: hidden;
  align-items: center;
  flex: 1;
  background-color: ${props => props.theme.colors.white};
  span {
    overflow: hidden;
  }
`;

