// Responsive
import React, { Fragment } from 'react';
import { AddFillIcon, CuponIcon } from '~/assets/icons';
import { ICupon } from '~/interfaces';
import { Button, CheckBox, Cupon, CustomCarrousel, Grid, Loading, Modal, TextField, Title, Typography } from '..';
import { keyValidationNumbersAndAlphabet } from '../TokenAuth/helpers';
import { OptionContainer } from './styled';
import _ from 'lodash';
type Props = {
    handleClose: VoidFunction,
    open: boolean,
    handleCurrentCupon: (value: ICupon | null) => void
    currentCupon: ICupon | null | undefined,
	cupon: string,
	handleCupon: (e: React.ChangeEvent<HTMLInputElement>) => void,
	addCupon: boolean,
	handleAddCupon: () => void,
	handleCancelAddCupon: () => void,
	activePosition: number,
	handleNext: VoidFunction,
	handleBack: VoidFunction,
	handleCanjearCupon: VoidFunction,
	loadingCupon?: boolean,
	dataCupones: ICupon[] | undefined,
	loadingConsultaCupones: boolean,
	xs: boolean,
	main?: boolean,
	cuponError: {
		error: boolean;
		helperText: string;
	}
};
const CuponesModal : React.FC<Props> = ({ 
	open, 
	handleClose,
	handleCurrentCupon,
	currentCupon,
	cupon,
	handleCupon,
	activePosition,
	handleNext,
	// handleBack,
	main,
	xs,
	loadingCupon,
	handleCanjearCupon,
	loadingConsultaCupones,
	dataCupones,
	cuponError,
} : Props) => {

	const disabledCuponButton = activePosition === 1 || main? cupon.length === 0 : false;

	
	return (
		<Modal 
			width={xs ? '436px' : '100%'}
			responsiveFullSize
			padding={xs ? '0 56px 20px 56px' : '0 16px 20px 16px'}
			title={<Grid container textAlign='center' justifyContent='center' padding='0 0 10px 0'>
				<Grid container direction='column'>
					<Title variant='h8'>{activePosition === 0 ? <>Cupones y <br/> promociones</> : 'Canjear cupón'}</Title>
					{
						(activePosition === 1 || main) && <Grid 
							padding='6px 0 0 0'
							container justifyContent='center'>
							<Typography color='grey70' variant='body2'>
							Escribe un código promocional
							</Typography>
						</Grid>
					}
				</Grid>
			</Grid> } 

			action={
				<Grid padding='10px 36px 36px 36px' container justifyContent='center'>
					<Button loading={loadingCupon} onClick={(activePosition === 1 || main) ? handleCanjearCupon :handleClose} disabled={disabledCuponButton} fullWidth variant='contained-round'>
						{(activePosition === 1 || main) ? 'Canjear ahora' : 'Aceptar'}
					</Button>
				</Grid>
			}
			handleClose={handleClose}
			open={open} 
			close 
		>
			<CustomCarrousel 
				activePosition={activePosition}
				items={_.filter([
					(
						main ? null : <Fragment key={1}>
							<OptionContainer onClick={handleNext} margin='10px 0 8px 0'>
								<Grid item flex>
									<Typography>Canjear cupón</Typography>
								</Grid>
								<AddFillIcon />
							</OptionContainer>
							<OptionContainer onClick={() => handleCurrentCupon(null)} ocher margin='0 0 16px 0'>
                
								<Grid item flex>
									<Typography>No usar cupón</Typography>
								</Grid>
								<CheckBox 
									onClick={() => handleCurrentCupon(null)}
									checked={!!(currentCupon === null)}
								></CheckBox>
							</OptionContainer>
							{
								(loadingConsultaCupones || !dataCupones) ? <Loading></Loading> : <>
									{
										dataCupones.length > 0 ? dataCupones.map((itemCupon, key) => <Cupon itemCupon={itemCupon} key={key} currentCupon={currentCupon} handleCurrentCupon={handleCurrentCupon} />) : <Grid container direction='column' justifyContent='center' textAlign='center' alignItems='center' padding='40px 0 20px 0'>
									
											<CuponIcon />
											<Typography variant='subtitle3' padding='8px 0 0 0'>
										Actualmente no <br/> cuentas con cupones
											</Typography>
										</Grid>
									}
								</>
							}
						</Fragment>
					
					),

					<Fragment key={1}>
						<form id="cupon form" onSubmit={disabledCuponButton ? undefined : ()=>handleCanjearCupon()}>

							<TextField {...cuponError} onKeyPress={keyValidationNumbersAndAlphabet} margin='10px 0 0 0' label='Código promocional' placeholder='Ingresa tu código promocional' value={cupon} onChange={handleCupon} />
						</form>
					</Fragment>
            
	
				], o=>o)}
			/>
		</Modal>
	);
};
export default (CuponesModal);