import React from 'react';
import LinkSpanBlueContainer from './styled';
import { useNavigate } from 'react-router-dom';
type Props = {
	children: JSX.Element | JSX.Element[] | string,
	externalLink?: string,
	goto?: string,
	margin?: string
	variant ?: string
};
const LinkSpanBlue : React.FC<Props> = ({  children, externalLink, margin, goto, variant } : Props) => {
	const navigate = useNavigate();

	const handleClick = () => {
		if(goto){
			navigate(goto);
		} else {
			window.open(externalLink);

		}

	};
	return (
		<LinkSpanBlueContainer variant={variant} margin={margin} onClick={handleClick}>{children}</LinkSpanBlueContainer>
	);
};
export default (LinkSpanBlue);