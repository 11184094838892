import React from 'react';
import { Grid, LoadingRing, RipplesButtons, Typography } from '..';
import ButtonStyled from './styled';
type Props = {
  onClick?: ()=>void,
  margin?: string,
  className?: string,
  disabled?: boolean,
  leftText: string,
  rightText: string,
  loading?: boolean,

};
const ButtonAddToCart : React.FC<Props> = ({ onClick, margin, disabled, className, leftText, rightText, loading } : Props) => {
	return (
		<RipplesButtons disabled={disabled || loading} style={{borderRadius: '15px'}} className={className} margin={margin} onClick={(!disabled && !loading) && onClick ? ()=>onClick() : undefined}>
			<ButtonStyled disabled={disabled}>
				{
					loading ? <LoadingRing /> : <>
						<Grid item padding='0 5px 0 0'>
							<Typography color="white" variant='body1'>{`${leftText} `}</Typography>
						</Grid>
						<Grid item>
							<Typography color="white" variant='body1'>{` ${rightText}`}</Typography>
						</Grid>	
					</>
				}
				
			</ButtonStyled>
		</RipplesButtons>
	);
};
export default (ButtonAddToCart);