export const TIPO_EXTRA_VARIANTE = 1;
export const TIPO_EXTRA_MODIFICADORES = 2;
export const TIPO_EXTRA_EXTRAS = 3;
export const TIPO_EXTRA_ESPECIAL = 4;

export const  TIPO_PLATAFORMA_WEB = 3;

export const  TIPO_ENTREGA_DOMICILIO = 1;
export const  TIPO_ENTREGA_RECOLECCION = 2;

export const  TIPO_LOGIN_CORREO = 1;
export const  TIPO_LOGIN_INVITADO = 2;
export const  TIPO_LOGIN_FB = 3;
export const  TIPO_LOGIN_GOOGLE = 4;


export const  TIPO_USUARIO_SIN_INFO = 5;
export const  TIPO_USUARIO_INCIAL = 1;
export const  TIPO_USUARIO_COMPLETADO = 2;

export const  TIPO_ACCION_CARRITO_ANIADIR = 1;
export const  TIPO_ACCION_CARRITO_MODIFICAR = 2;
export const  TIPO_ACCION_CARRITO_EDITAR = 3;

export const TIPO_STATUS_ORDEN = 1;

export const DINAMICAS_TIPO = {
	REGISTRO_WEB: {
		nTipo: 1,
		titulo: 'Registra tu ticket'
	},
	SIN_REGISTRO: {
		nTipo: 2,
		titulo: 'Consultar canjes'
	},
	INFORMATIVA: {
		nTipo: 3,
		titulo: 'Consulta la bases'
	}
};