import styled from 'styled-components';

export interface CheckoutContainerProps {
  showSideBarIcon?: boolean;
  padding?: string;
}

export const CheckoutContainer = styled.div<CheckoutContainerProps>`
    display: flex;
    background-color: ${props => props.theme.colors.bgColor};
    justify-content: center;

    .leftSide {
      box-shadow: 12px -1px 11px -10px rgba(0,0,0,0.50);
      -webkit-box-shadow: 12px -1px 11px -10px rgba(0,0,0,0.50);
      -moz-box-shadow: 12px -1px 11px -10px rgba(0,0,0,0.50);
    }
    
    .footer {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 16px 0 0 0;

    }
    @media (max-width: 952px) {
        height: auto;
        .leftSide {
          box-shadow: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
        }
    }


    .item-checkout-container {
      display: flex;
      padding: 25px 30px 30px 30px;
      flex-direction: column;
      box-sizing: border-box;
      max-width: 438px;
      width: 100%;
      @media (max-width: 650px) {
        padding: 9px 30px 0px 30px;
      }
    }

    @media (max-width: 952px) {
      align-items: center;
        flex-direction: column;
        padding-bottom: 150px;
      }
    @media (max-width: 650px) {
      padding-bottom: 120px;
    }

    .pointer {
      cursor: pointer;
    }

`;

export interface RoundCardContainerProps {
  margin?: string;
  padding ?: string;
  titlePadding ?: string;
}

export const RoundCardContainer = styled.div<RoundCardContainerProps>`
    background: ${props => props.theme.colors.white};
    display: flex;
    box-sizing: border-box;
    flex-direction: column;

    padding: ${props => props.padding || '0px 16px 8px 16px'};
    border: 1px solid ${props => props.theme.colors.borderLightGrey};
    border-radius: 22px;

    margin: ${props => props.margin};
    transition: all 0.3s ease-in-out;

    .titleContainer {
      font-weight: ${props => props.theme.text.weight.bold};
      font-size: ${props => props.theme.text.size.subtitle1};
      font-family: ${props =>props.theme.text.font.primary};
      padding: ${props => props.titlePadding || '6px 0 6px 0'};
      border-bottom: ${props => `1px solid ${props.theme.colors.borderLightGrey}`};
    }
`;

export interface ProductoContainerProps {
  padding?: string;
}

export const ProductoContainer = styled.div<ProductoContainerProps>`
    border-bottom: ${props => `1px solid ${props.theme.colors.borderLightGrey}`};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &:hover {
        cursor: pointer;
    }
`;

