import React from 'react';
import Button from '../Button';
import { PayButtonContainer } from '../GeneralLayout/styled';
import Grid from '../Grid';
type Props = {
  children: JSX.Element | JSX.Element[] | string,
  onClick?: ()=>void,
  disabled?: boolean,
  loading?: boolean,
  minWidth?: string,
  variant?: string,
  bottom?: string,
  finalHeight?: string
};
const FloatButton : React.FC<Props> = ({ onClick, disabled, loading, minWidth, variant, children, bottom, finalHeight} : Props) => {
	return (
		<>
			<PayButtonContainer bottom={bottom}>
				<Button onClick={(!disabled && !loading && onClick) ? ()=>onClick() : undefined} minWidth={minWidth} variant={variant || 'contained-round'}>
					{children}
				</Button>
			</PayButtonContainer>
			{
				finalHeight && <Grid container height={finalHeight}></Grid>
			}
		</>
		
	);
};
export default (FloatButton);