import React from 'react';
import { DataCarritoTypes } from '~/interfaces';
import { pricePrettify } from '~/utils';
import Button from '../Button';
import { Footer } from './styled';
type Props = {
  singleMenu?: boolean;
  carrito: DataCarritoTypes,
  handleCheckout: (value: number) => void,
  handleClose: VoidFunction,
  carritoEmpty: boolean,
  disabled?: boolean
};
const CarritoFooter : React.FC<Props> = ({ carrito, singleMenu, handleCheckout, handleClose, carritoEmpty, disabled} : Props) => {
	return (
		<Footer singleMenu={singleMenu}>
			{
				carritoEmpty ? <>
					<Button disabled={disabled} onClick={handleClose}  margin='10px 0 10px 0' color='primary' variant='contained-round'>
					Agregar productos
					</Button>
				
				</> : <>
					<Button disabled={disabled} onClick={()=>handleCheckout(carrito.nValor)} padding='10px' margin='10px 0 0 0' color='primary' variant='contained-round'>
						{`Pagar ${pricePrettify(carrito.nTotal)}`}
					</Button>

					<Button onClick={handleClose}  margin='10px 0 10px 0' color='primary' variant='outlined-round'>
					Agregar más productos
					</Button>
				</>
				
			}
			
			
		</Footer>
	);
};
export default (CarritoFooter);