import { useState, useEffect } from 'react';

const useCardMedia = (src: string, staticImg?: boolean) => {
	const [loadImg, setLoadImg] = useState<boolean | null>(true);
	const [errorImg, setErrorImg] = useState<boolean | null>(false);
	const handleloadImg = () => {
		setLoadImg(false);
	};
	const handleError = () => {
		setErrorImg(true);
		setLoadImg(null);
	}; 
	const handleloadError = () => {
		setErrorImg(false);
	};

	useEffect(() => {
		if(!staticImg){
			/* if(!loadImg){
				setLoadImg(true);
			} */
			setLoadImg(true);
			setLoadImg(false);

			setErrorImg(false);

		}
	}, [src]);
	

	return {
		loadImg,
		handleloadImg,
		setLoadImg,
		handleError,
		errorImg,
		handleloadError
	};
};

export default useCardMedia;