import styled from 'styled-components';
import Grid from '../Grid';

export interface ChevronUpStyledProps {
    isShowing?: boolean;
}

export interface GridStyledProps {
    isShowing?: boolean;
}

export const ChevronUpStyled = styled.div <ChevronUpStyledProps>`
    stroke:black;
    transition: all 0.3s ease ;
    transform: ${props => props.isShowing ? 'rotate(180deg)' : ''};
`;

export const GridStyled = styled(Grid) <GridStyledProps>`
    overflow: hidden;
    max-height: ${props => props.isShowing ? '100%' : '0'};
    opacity: ${props => props.isShowing ? '1' : '0'};
    transition: all 0.3s ease-in-out;
`;