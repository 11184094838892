import styled, {css} from 'styled-components';

export interface ContainerProps {
  padding?: string;
  paddingTop?: string;
  paddingBottom?: string;
  responsivePadding?: boolean;
  margin?: string;
  maxWidth?: string;
  center?: boolean;
}

export const Container = styled.div<ContainerProps>`
    box-sizing: border-box;
    transition: padding 0.3s ease;
    display: flex;
    flex-direction: column;
    margin: ${props => props.margin};
    ${props => props.responsivePadding ? css`
      padding-bottom: ${props.paddingBottom};
      padding-bottom: ${props.paddingTop};
      @media (max-width: 1300px) {
        padding-left: 58px;
        padding-right: 58px;
      }
      @media (max-width: 952px) {
        padding-left: 16px;
        padding-right: 16px;
      }
    `: css`
        padding: ${(props.padding || `${props.paddingTop || '10px'} 5% ${props.paddingBottom || '10px'} 5%`)};
    `};
    
    align-items: ${props => props.center ? 'center' : null};
    .responsiveContainer {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      max-width: ${props => props.maxWidth || '343px'};
      flex-direction: column;

    }
`;

export default Container;