import React from 'react';
import { CheckBoxBlankIcon, CheckBoxIcon, RadioButtonCheckedIcon, RadioButtonUcheckedIcon } from '~/assets/icons';
import IconButton from '../IconButton';
import { CheckboxContainer } from './styled';
type Props = {
	checked?: boolean,
	onClick?: () => void,
	radio?: boolean,
	checkedColor?: string,
	disabled?: boolean
};
const CheckBox : React.FC<Props> = ({ onClick, checked, radio, disabled, checkedColor } : Props) => {
	return (
		<CheckboxContainer checked={checked}>

			<IconButton disabled={disabled} onClick={disabled ?  undefined : onClick}>
				<div className='icon'>
					<div className='check'>
						{
							radio ? <RadioButtonCheckedIcon color={checkedColor || (disabled ? 'disabledFontGrey' :'black')} /> :  <CheckBoxIcon color={checkedColor || (disabled ? 'disabledFontGrey' :'black')}></CheckBoxIcon> 
						}
															
					</div>
					<div className='uncheck'>
						{
							radio ? <RadioButtonUcheckedIcon height='25' width='25' color={(disabled ? 'disabledFontGrey' :'black')}  /> :  <CheckBoxBlankIcon color={(disabled ? 'lightGrey' :'black')}></CheckBoxBlankIcon>
						}
															
					</div>
														
				</div>
			</IconButton>
		</CheckboxContainer>
	);
};
export default (CheckBox);