import React from 'react';
import { LoadingContainer } from './styled';

type Props = {
	minWidth?: string,
	marginTop?: string
};

const Loading : React.FC<Props> = ({minWidth, marginTop}: Props) => {
	return (
		<LoadingContainer minWidth={minWidth} marginTop={marginTop}>
			<div className="spinner-10"></div>
		</LoadingContainer>
	);
};
export default (Loading);
