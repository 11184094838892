import styled from 'styled-components';

export interface ProfileContainerProps {
  padding?: string;
}

export const ProfileContainer = styled.div<ProfileContainerProps>`
        aspect-ratio: 1;
        border-radius: 50%; 
        height: 40px;
        z-index: 2;
        width: 40px;
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        border: 1px solid ${props => props.theme.colors.borderLightGrey};
        background-color: ${props => props.theme.colors.yellow};
        cursor: pointer;
`;
