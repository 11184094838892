import styled from 'styled-components';

export interface TextFieldProps {
  icon?: boolean;
  error?: boolean;
  check?: boolean;
  open?: boolean;
  fullWidth?: boolean
}

export interface TextFieldContainerInputProps {
  icon?: boolean;
  padding?: string;
}

export const TextFieldInput = styled.input<TextFieldProps>`
    padding: 12px 12px 12px 24px;
    box-sizing: border-box;
    border: none !important;
    ::placeholder,
    ::-webkit-input-placeholder {
      font-family: ${props =>props.theme.text.font.primary};
      font-size: ${props =>props.theme.text.size.subtitle2};
      font-weight: ${props =>props.theme.text.weight.medium};
      text-align: center;
      color: ${props =>props.theme.colors.grey};

    }
    :-ms-input-placeholder {
      font-family: ${props =>props.theme.text.font.primary};
      font-weight: ${props =>props.theme.text.weight.medium};
      text-align: center;
      color: ${props =>props.theme.colors.grey};

    }
    flex: auto;
    font-family: ${props =>props.theme.text.font.primary};
    font-size: ${props => props.theme.text.size.subtitle1};
    background-color: ${props =>props.theme.colors.greyF5};
    &:focus {
      outline: none;
    }
    width: 100%;
`;


export const InputContainer = styled.div<TextFieldProps>`
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  flex: 1;
`;

export const TextFieldContainerInput = styled.div<TextFieldProps>`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    font-family: ${props => props.theme.text.font.primary};
    background-color: ${props => props.theme.colors.greyF5};
    height: 40px;
    box-sizing: border-box;
    border-radius: 24px;
    overflow: hidden;
    transition: width 0.3s ease;
    max-width: ${props => props.fullWidth ? '100%' : '358px'};
    min-width: ${props => props.fullWidth ? '100%' : '233px'};

    padding-right: 10px;
    flex: 1;
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
`;

