import {
	useState, useEffect
} from 'react';
/* 
Este hook te ayuda a validar si el Size de la pantalla es menor que el Size de parámetro enviado 
(Es como css pero en hook)
*/
const useMediaQuery = (size: number) => {
	const [matches, setMatches] = useState(false);


	useEffect(() => {
		const media = window.matchMedia(`(min-width: ${size}px)`);
		if (media.matches !== matches) {
			setMatches(media.matches);
		}
		const listener = () => {
			setMatches(media.matches);
		};
		media.addEventListener('change', listener);
		return () => media.removeEventListener('change', listener);
	}, [matches, size]);

	return matches;
};

export default useMediaQuery;