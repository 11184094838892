import styled from 'styled-components';

export const ImageContainer = styled.div`
    width: 30%;
    display: flex;
    align-items: flex-end;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: -1;
    left: 20px;
    img {
        max-height: 713px;
    }

`;