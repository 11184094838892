//@flow
import React from 'react';
import { LoadingRing, RipplesButtons } from '..';
import { ProductTextFieldContainer, TextFieldInput, TextFieldContainerInput } from './styled';
import { MinusIcon, AddFillIcon, DeleteIcon } from '~/assets/icons';
import { LoadingContainer } from '../QuantityContainer/styled';
import { isNumber } from 'lodash';
import { evitarTeclasNumber, evitarWheel } from '~/utils';

type Props = {
	cantidad: number | string;
	handleCantidad: (val: number | string) => void;
	handleRemove?: () => void;
	handlePlus: () => void;
	removeLoading?: boolean;
	handleMinus: () => void;
	min?: number;
	className: string;
};

const ProductTextField = ({ cantidad, handlePlus, handleCantidad, removeLoading, handleRemove, min, handleMinus }: Props) => {
	const minVal = isNumber(min) ? min : 1;

	return (
		<ProductTextFieldContainer disabled={cantidad === minVal}>
			<RipplesButtons disabled={(cantidad === minVal && !handleRemove) || removeLoading} onClick={cantidad === minVal && handleRemove ? handleRemove : handleMinus} className="minus button">
				<div className="hide">
					{removeLoading ? (
						<LoadingContainer>
							<div className="svgContainer">
								<LoadingRing color="greyCard" />
							</div>
						</LoadingContainer>
					) : cantidad === minVal && handleRemove ? (
						<DeleteIcon />
					) : (
						<MinusIcon color={cantidad === minVal ? 'grey' : 'black'} style={{ paddingBottom: '2px' }} />
					)}
				</div>
			</RipplesButtons>
			<TextFieldContainerInput>
				<TextFieldInput id="searcher" autoComplete="off" onKeyDown={evitarTeclasNumber} onWheel={evitarWheel} type={'number'} onChange={(e) => handleCantidad(parseInt(e.target.value))} value={cantidad} />
			</TextFieldContainerInput>
			<RipplesButtons onClick={handlePlus} className="add button">
				<div className="hide">
					<AddFillIcon />
				</div>
			</RipplesButtons>
		</ProductTextFieldContainer>
	);
};

export default ProductTextField;
