import moment from 'moment';
import errors from '~/constants/errors';
import validator from 'validator';

export const errorEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const errorPasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,25}$/;

export const errorNombresRegex = /^[A-zÀ-ú ]+$/i;
export const errorNumeroCelularRegex = /^[0-9+]{3,15}$/gm;
export const errorNumeberRegex = /^[0-9+]{5}$/gm;

export const errorDateRegex = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;

export const errorTextRegex = /^[A-Za-zÀ-ú 0-9.,]+$/;

export const errorDetallesRegex = /^[A-Za-zÀ-ú 0-9.,#]+$/;

export const errorCommentRegex = /^[A-Za-zÀ-ú 0-9.,¡¿!?]+$/;

export const validNumeroCasaRegex = /^([A-Z]{1,2}[-/.]|[A-Z]{1,2})?[0-9]+([A-Z]{1,2}|[-/.][A-Z]{1,2})?$/gi;
export const validDinamicaRegex = /^([\d]{2})([A-ZÑ\d]{2,5})([\d]{1,6})([A-ZÑ]{3})$/gi;

export const handleValidCodigoPostal = (codigoPostal: string, codigoPostalError: boolean) => {
	const errorTextHelper = codigoPostal.match(errorNumeberRegex);
	const isNotEmptyText = codigoPostal !== '';

	const errorText = isNotEmptyText && errorTextHelper === null;

	return {
		error: errorText || codigoPostalError,
		check: !!(isNotEmptyText && (errorTextHelper || codigoPostalError)),
		helperText:codigoPostalError ? 'No contamos con servicio a domicilio a este código postal. Puedes realizar tu pedido para recoger en la sucursal más cercana.' : ( errorText ? errors.cpError : ''),
	};
};

export const handleValidText = (textValue: string, errorsIndex: string) => {
	const errorTextHelper = textValue.match(errorTextRegex);
	const isNotEmptyText = textValue !== '' && textValue.length > 0;

	const errorText = isNotEmptyText && errorTextHelper === null;

	return {
		error: errorText,
		check: !!(isNotEmptyText && errorTextHelper),
		helperText: errorText ? errors[errorsIndex] : '',
	};
};

export const handleValidHouseNumber = (houseNumber: string, errorsIndex: string) => {
	const isInvalidHouseNumber = !houseNumber.match(validNumeroCasaRegex)?.length;
	const isNotEmptyHouseNumber = houseNumber !== '';
	const errorHouseNumber = isInvalidHouseNumber && isNotEmptyHouseNumber;

	return {
		error: errorHouseNumber,
		check: !!(isNotEmptyHouseNumber && !isInvalidHouseNumber),
		helperText: errorHouseNumber ? errors[errorsIndex] : '',
	};
};

export const handleValidEmail = (email: string) => {
	const errorEmailHelper = email.match(errorEmailRegex);
	const isNotEmptyEmail = email !== '' && email.length > 3;

	const errorEmail = isNotEmptyEmail && errorEmailHelper === null;

	return {
		error: errorEmail,
		check: !!(isNotEmptyEmail && errorEmailHelper),
		helperText: errorEmail ? errors.emailError : '',
	};
};

export const handleHelperValidRegex = (value: string, regex: RegExp, helperText: string) => {
	const errorValueHelper = value !== undefined && value.match(regex);
	const isNotEmptyvalue = value !== '';

	const errorvalue = isNotEmptyvalue && errorValueHelper === null;

	return {
		error: errorvalue,
		check: !!(isNotEmptyvalue && errorValueHelper),
		helperText: errorvalue ? helperText : '',
	};

};

export const handleValidPhoneRegex = (value= '') => {
	const errorValueHelper = value !== undefined && validator.isMobilePhone(value);
	const isNotEmptyvalue = value !== '';

	const errorvalue = isNotEmptyvalue && !errorValueHelper;

	return {
		error: errorvalue,
		check: !!(isNotEmptyvalue && errorValueHelper),
		helperText: errorvalue ? errors.numeroCelular : '',
	};

};

export const handlePasswordConfirmValidRegex = (password: string,passwordConfirm : string) => {
	const errorValueHelper = passwordConfirm.match(errorPasswordRegex);
	const isNotEmptyvalue = passwordConfirm !== '';

	const errorvalue = isNotEmptyvalue && ( passwordConfirm !== password);

	return {
		error: errorvalue,
		check: !!(isNotEmptyvalue && errorValueHelper),
		helperText: errorvalue ? errors.checkPassword : '',
		disabled: password === '',
	};

};

const dateMin = moment().subtract(13, 'years').format('YYYY-MM-DD');
const dateMax = moment().subtract(130, 'years').format('YYYY-MM-DD');

export const getValidFechaDeNacimiento = (fechaDeNacimiento: string) => {
	const errorDateHelper = fechaDeNacimiento.match(errorDateRegex);
	const isNotEmptyEmail = fechaDeNacimiento !== '';

	const errorDate = isNotEmptyEmail && (errorDateHelper === null || !moment(fechaDeNacimiento).isBetween( dateMax, dateMin, null, '[]'));

	return {
		error: errorDate,
		check: !!(isNotEmptyEmail && errorDateHelper),
		helperText: '',
	};
};

export const validateDateFechaNacimiento = (fechaDeNacimiento: string) => {
	const errorDateHelper = fechaDeNacimiento.match(errorDateRegex);
	const isNotEmptyEmail = fechaDeNacimiento !== '';

	return isNotEmptyEmail && (errorDateHelper === null || !moment(fechaDeNacimiento).isBetween( dateMax, dateMin, null, '[]'));
};

export const firstLetterUpperCase = (str: string) => {
	return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

export const handleValidDinamicaFolio = (folio: string) => {
	const errorFolioHelper = !folio.match(validDinamicaRegex)?.length;
	const isNotEmptyFolio = folio !== '';
	const errorFolio = errorFolioHelper && isNotEmptyFolio;

	return {
		error: errorFolio,
		check: !!(isNotEmptyFolio && !errorFolioHelper),
		helperText: errorFolio ? errors.dinamicaFolioError : '',
	};
};