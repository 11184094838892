import styled, { css } from 'styled-components';

export interface DividerProps {
    margin?: string;
    checked?: boolean;
    vertical?: boolean
  }
  
export const Divider = styled.div<DividerProps>`

    ${props => props.vertical ? css`
      width: 1px !important;
      height: 100%;
    
    ` : css`
      height: 1px !important;
    
    `}
    background-color: ${props => props.theme.colors.borderGrey};
    margin: ${props => props.margin};
    opacity: 0.5;
`;

export default Divider;
