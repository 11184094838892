import styled from 'styled-components';
import { PREV } from './helpers';
export interface PropsItem {
  img?: string;
}
export interface PropsCarouselContainer {
  sliding?: boolean;
}
export interface PropsCarouselSlot {
  order?: string | number;
}

export const Item = styled.div<PropsItem>`
  text-align: center;
  padding: 100px;
  background-image: ${props => `url(${props.img})`};
  background-size: cover;
`;

export const CarouselContainer = styled.div <PropsCarouselContainer>`
  display: flex;
  transition: ${props => (props.sliding ? 'none' : 'transform 1s ease')};
  transform: ${props => {
		if (!props.sliding) return 'translateX(calc(-100% ))';
		if (props.dir === PREV) return 'translateX(calc(2 * (-100% )))';
		return 'translateX(0%)';
	}};
`;

export const Wrapper = styled.div`
  width: 100%;
  justify-content: center;
  overflow: hidden;
`;

export const CarouselSlot = styled.div<PropsCarouselSlot>`
  flex: 1 0 100%;
  flex-basis: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  order: ${props => props.order};
  transition: height 0.3s ease-in-out;
`;


