import styled, { css } from 'styled-components';
import { getTextVariant } from '~/utils';

export interface LinkSpanBlueContainerProps {
	margin?: string;
	variant?: string;
}

export const LinkSpanBlueContainer = styled.span<LinkSpanBlueContainerProps>`
	margin: ${(props) => props.margin};
	${(props) => css`
		color: ${props.theme.colors.blue};
		cursor: pointer;
		text-decoration: underline;
	`}

	${(props) => props.variant
		? getTextVariant(props)
		: css`
			font-family: ${props.theme.text.font.primary};
			font-size: ${props.theme.text.size.subtitle1};
	`}
`;

export default LinkSpanBlueContainer;
