import React from 'react';
import Button from '../Button';
import Grid from '../Grid';
import { ProfileContainer } from './styled';
type Props = {
  edit?: boolean,
  src: string,
  onClick: VoidFunction
};
const Profile : React.FC<Props> = ({ edit, src, onClick } : Props) => {
	return (
		<Grid item container direction='column' alignItems='center'>
			<ProfileContainer onClick={onClick}>
				<img loading="lazy" draggable="false" width="18px" height="22.74px" alt='Perfil' src={src}></img>
			</ProfileContainer>
			{
				edit && <Button onClick={onClick} padding='2px 5px 2px 5px' variant='mini'>Editar</Button>
			}
		</Grid>
	);
};
export default (Profile);