import styled from 'styled-components';

export const EmptyContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 342px;
    @media (max-width: 650px) {
        max-width: 341px;
    }
    .textContainer {
        width: 100%;
        max-width: 228px;
    }
`;