import styled from 'styled-components';
import {
	Link
} from 'react-router-dom';
export interface Props {
    bold?: boolean;
    padding?: string;
    color?: string;
    margin?: string;
    family?: string;
    variant?: string
  }

const ATag = styled(Link)<Props>`
  padding: ${props =>props.padding};
  margin: ${props =>props.margin};
  font-size: ${props =>props.theme.text.size[props.variant || 'caption']};
  color: ${props =>props.theme.colors[props.color || 'red']};
  font-weight: ${props =>props.bold ? props.theme.text.weight.bold : props.theme.text.weight.regular};
  font-family: ${props =>props.theme.text.font.primary};
  font-weight: ${props =>props.theme.text.weight.medium};


`;
export default ATag;