import React from 'react';
import WarningModal from '../WarningModal';
import Typography from '../Typography';
import Grid from '../Grid';
type Props = {
    handleClickItem: () => void, open: boolean,
	handleClose: () => void,
	nDiasAnticipacion?: number,
	descripcion?: JSX.Element | JSX.Element[] | string,
};
const WarningSobrePedido : React.FC<Props> = ({ handleClickItem, open,
	handleClose, nDiasAnticipacion, descripcion } : Props) => {
	return (
		<WarningModal 
			width='341px'
			height={descripcion ?  undefined : '310px'}
			cancelLabel={descripcion ? 'Cancelar' : 'Mmm...Mejor no'}
			aceptLabel={descripcion ? 'Aceptar' : 'Sí, entiendo'}
			warningLabel={
				<>¡Ojo! 👀</>
			}
			handleAcept={() => {
				handleClose();
				handleClickItem();
			}}
			open={open} handleClose={handleClose}>
			<Grid padding={descripcion ? '20px 15px 0 15px' : '21px 0 0 0'} justifyContent='center' container textAlign='center'>
				<Typography variant='subtitle1regular'>{descripcion || <>Este producto es sobre pedido, <br />tarda {nDiasAnticipacion} días en estar listo.</>}</Typography>
			</Grid>
		</WarningModal>
	);
};
export default (WarningSobrePedido);