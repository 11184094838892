import styled, {keyframes, css} from 'styled-components';

interface HtmlToolTipProps {
    tooltipwidth?: string;
    open?: boolean;
}

const toolTipFade = keyframes`
  from {
      opacity: 0;
      margin-top: 10px;
      display:none;
    
  }
  to {
      opacity: 1;
      margin-top: 20px;
      display:block;
    
  }
`;

export const HtmlToolTip = styled.div<HtmlToolTipProps>`
    background-color: ${props=>props.theme.colors.white};
    border-top: 5px solid ${props=>props.theme.colors.primary};
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px;
    opacity: 0;
    border-radius: 6px;
    margin-top: 10px;
    position: absolute;
    display:none;
    z-index: 100009;
    width: ${props=>props.tooltipwidth};
    ${props => props.open ? css`
    animation-name: ${toolTipFade};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    display:block;
    margin-top: 30px;

    ` : null}
`;

export const HtmlToolTipInvisible = styled.div<HtmlToolTipProps>`
    opacity: 0;
    border-radius: 6px;
    display:none;
    position: absolute;
    z-index: 100000;
    width: ${props=>props.tooltipwidth};
    padding: 0 20px 20px 20px;
    ${props => props.open ? css`
      opacity: 1;
      margin-top: 0px;
      display:block;
      background-color: transparent;
    ` : null}
`;


export const HtmlToolTipContainer = styled.div`
  padding: 10px;
  opacity: 1;

`;

