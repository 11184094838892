import styled from 'styled-components';

export interface CircleStepProps {
  active?: boolean;
}

export const CircleStep = styled.div<CircleStepProps>`
    aspect-ratio: 1;
    border-radius: 50%; 
    height: 30px;
    width: 30px;
    border: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors[props.active ? 'white' : 'primary']};
    display: flex;
    align-items: center;
    justify-content: center;
`;