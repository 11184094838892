import styled, { css } from 'styled-components';
import { hide, show } from '~/styles/keyframes';

export interface hideShowComponentProps {
  show: boolean | null;
}

export const HideShowComponentContainer = styled.div<hideShowComponentProps>`
    ${props => (props.show === true ? css`
    animation: ${show} 0.2s forwards;
    ` : props.show === false ? css`
    animation: ${hide} 0.2s forwards;
    ` : css`
    display: block;
    opacity: 1;
    `)}
`;