import { keyframes } from 'styled-components';

export const hide = keyframes`
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		display: none !important;

		div {
			display: none !important;
		}
	}
`;

export const toolTipFade = keyframes`
    from {
        margin-left: 0;
    }

    to {
        margin-left: 100%;
    }
`;

export const show = keyframes`
	0% {
		opacity: 0;
		display: none;
	}
	100% {
		display: block;
		opacity: 1;
	}
`;


export const productoHide = keyframes`
	0% {
		opacity: 1;
		display: flex;
	}

	90% {
		opacity: 0;
	}

	100% {
		display: none;
		opacity: 0;
	}
`;

export const productoShow = keyframes`
	0% {
		height: 0px;
		overflow: hidden;
	}

	90% {
		height: 35px;
	}

	100% {
		overflow: visible;
		height: 40px;
	}
`;

export const TextFieldToolTipFade = keyframes`
    from {
        opacity: 0;
        margin-top: -10px;
        display:none;
    }

    to {
        opacity: 1;
        margin-top: 0;
        display:block;
    }
`;