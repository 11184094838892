import styled from 'styled-components';
import Grid from '../Grid';

export interface CardCreditCardProps {
  principal?: boolean;
}

export const GridStyled = styled(Grid)<CardCreditCardProps>`
    border:1px solid ${props => props.principal ? props.theme.colors.green176:props.theme.colors.disabledTextFieldGrey};
    border-radius:4px;
`;