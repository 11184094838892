import React from 'react';
import { Button, Grid, MenuItemChevron, Typography } from '~/components';
import { CarritoTypes } from '~/interfaces';
import { pricePrettify } from '~/utils';
import { ProductoContainer } from '../../styled';
type Props = {
    item: CarritoTypes,
    e: number,
	onClick: (value: CarritoTypes) => void
	disabled?: boolean
	handleRemove?: (item: CarritoTypes) => void
	loadingRemove?: boolean
};
const ProductCard : React.FC<Props> = ({ item, e, onClick, disabled, handleRemove, loadingRemove } : Props) => {
	
	return (
		<ProductoContainer onClick={disabled ? undefined : ()=>onClick(item)}>
			<MenuItemChevron disabled={disabled} onClick={disabled ? undefined : ()=>onClick(item)} padding='6px 0 0 0' titleVariant='subtitle2'>
				{`Producto (${e+1})`}
			</MenuItemChevron>
			<Typography variant='regular'>
				{`${item.cDescripcion} (x${item.nCantidad})`}
			</Typography>
			{
				item.jExtras.length > 0 && <>
					{
						item.jExtras.map((extra, i) => <Grid  padding='0 5px 0 0' key={i}>
							<Typography variant='regular'>
								{`${extra.cDescripcion} ${extra.nCantidad? `(x${extra.nCantidad})` : ''}`}
							</Typography>
						</Grid>)
					}
				</>
			}
			<Grid justifyContent={handleRemove ? 'space-between' : 'flex-end'}alignItems='center' container padding='5px 0 10px 0'>
				{
					handleRemove && !disabled && <Button onClick={() => handleRemove(item)} loading={loadingRemove} variant='underline-subtitle2' padding='0px'>Eliminar</Button>
				}
				
				<Typography variant='body3' >
					{pricePrettify(item.nTotal)}
				</Typography>

			</Grid>
		</ProductoContainer>
	);
};
export default (ProductCard);