import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PinItem from './pinItem';
import Grid from '../Grid';
/**
 */
class PinInput extends Component {
	constructor(props) {
		super(props);
  
		this.values = Array(props.length)
			.fill('')
			.map((x, i) => props.initialValue.toString()[i] || '');
		this.elements = [];
		this.currentIndex = 0;

		this.onPaste = this.onPaste.bind(this);
	}

	componentDidMount() {
		// Setting focus on the first element
		if (this.props.focus && this.props.length) this.elements[0].focus();
	}

	clear() {
		this.elements.forEach(e => e.clear());
		this.values = this.values.map(() => undefined);
		this.elements[0].focus();
	}

	focus() {
		if (this.props.length) this.elements[0].focus();
	}

	/**
   */
	onItemChange(value, isPasting, index) {
		const { length, onComplete, onChange } = this.props;
		let currentIndex = index;

		this.values[index] = value;

		// Set focus on next
		if (value.length === 1 && index < length - 1) {
			currentIndex += 1;
			this.elements[currentIndex].focus();
		}

		// Notify the parent
		const pin = this.values.join('');

		if (!isPasting) {
			onChange(pin, currentIndex);
		}

		if (pin.length === length) {
			// for pasting, trigger onComplete only when the last input triggers onChange
			if (isPasting && index < length - 1) {
				return;
			}

			onComplete(pin, currentIndex);
		}
	}

	onBackspace(index) {
		if (index > 0) {
			this.elements[index - 1].focus();
		}
	}

	onPaste(value) {
		const { length } = this.props;
		if (value.length !== length) {
			return;
		}

		this.elements.forEach((el, index) => el.update(value[index], true));
	}

	render() {
		return (
			<form name="inputnumber" 
				autoComplete="off">
				<div style={this.props.style} className='pincode-input-container'>
					<Grid item container justifyContent="center" spacing='10px' padding='25px 0 20px 0'>
						{this.values.map((e, i) => (
							<PinItem
								initialValue={e}
								errorToken={this.props.errorToken}
								ref={n => (this.elements[i] = n)}
								key={i}
								disabled={this.props.disabled}
								onBackspace={() => this.onBackspace(i)}
								secret={this.props.secret || false}
								onChange={(v, isPasting) => this.onItemChange(v, isPasting, i)}
								type={this.props.type}
								inputMode={this.props.inputMode}
								validate={this.props.validate}
								inputStyle={this.props.inputStyle}
								inputFocusStyle={this.props.inputFocusStyle}
								autoSelect={this.props.autoSelect}
								onPaste={i === 0 ? this.onPaste : null}
								regexCriteria={this.props.regexCriteria}
								ariaLabel={this.props.ariaLabel}
								placeholder={this.props.placeholder}
								secretDelay={this.props.secretDelay}
							/>
						))}
					</Grid>
				</div>
			</form>
		);
	}
}

PinInput.propTypes = {
	initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	length: PropTypes.number.isRequired,
	type: PropTypes.string,
	onComplete: PropTypes.func,
	validate: PropTypes.func,
	secret: PropTypes.bool,
	disabled: PropTypes.bool,
	focus: PropTypes.bool,
	onChange: PropTypes.func,
	errorToken: PropTypes.any,
	inputMode: PropTypes.string,
	style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
	inputStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
	inputFocusStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
	autoSelect: PropTypes.bool,
	regexCriteria: PropTypes.any,
	ariaLabel: PropTypes.string,
	placeholder: PropTypes.string,
};

PinInput.defaultProps = {
	initialValue: '',
	type: 'numeric',
	secret: false,
	validate: null,
	focus: false,
	disabled: false,
	onChange: () => {},
	onComplete: () => {},
	inputMode: undefined,
	style: {},
	inputStyle: {},
	inputFocusStyle: {},
	autoSelect: true,
	regexCriteria: /^[a-zA-Z0-9]+$/,
	ariaLabel: '',
	placeholder: ''
};

export default PinInput;
/* import React, { ChangeEvent } from 'react';
import {TextField, TokenContainer, TextFieldContainerInput} from './styled';
import {keyPress, IDictionary} from './helpers';
import Grid from '../Grid';

type Props = {
	pinValue: IDictionary<string>,
	handlePinvalue: (i: number, e: ChangeEvent<HTMLInputElement>, pinValue: IDictionary<string>) => void, status: number, loading: boolean, 
	disabled?: boolean,
};

const TokenAuth : React.FC<Props> = ({pinValue, handlePinvalue, status, loading, disabled}) => {


	const errorToken = status > 2;
	return <TokenContainer>
		<Grid item container direction="column" >
		
			<form name="inputnumber" 
				autoComplete="off">
				<Grid item container justifyContent="center" spacing='10px' padding='25px 0 20px 0'>
					{
						['pin1', 'pin2', 'pin3', 'pin4'].map((item, i)=>(
							<TextFieldContainerInput disabled={disabled} error={errorToken} key={item}>
                  
								<TextField autoComplete='off' type="number" disabled={loading || disabled} id={item} value={pinValue[item]} 
									onChange={(e)=>{
										handlePinvalue(i, e, pinValue);
									}} 
									onKeyDown={(e) => keyPress(e, i,pinValue)}
								/>
							</TextFieldContainerInput>
						))
					}
           
				</Grid>
			</form>
		</Grid>

	</TokenContainer>;
};

export default (TokenAuth); */