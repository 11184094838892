import styled from 'styled-components';
import colors from '~/styles/colors';

export interface TextFieldProps {
  icon?: boolean;
  error?: boolean;
  check?: boolean;
  disabled?: boolean;
}

export interface TextFieldContainerInputProps {
  icon?: boolean;
  margin?: string;
  padding?: string;
  width?: string;
  fullWidth?: boolean;
}

export const TextFieldInput = styled.input<TextFieldProps>`
    padding: 10px 12px 10px 12px;
    border: none !important;
    flex: auto;
    background-color: ${props =>props.theme.colors[props.disabled ? 'inputGrey' : 'white']};
    font-family: ${props =>props.theme.text.font.primary};
    font-size: ${props => props.theme.text.size.subtitle1};
    color: ${props =>props.theme.colors[props.disabled ? 'disabledTextFieldGrey' : 'textFieldLabel']};
    box-sizing: border-box;

    border-radius: ${props => props.icon ? '4px 0px 0px 4px' : '4px'};
    width: 100%;
    &:focus {
      outline: none;
    }
`;


export const IconContainer = styled.div<TextFieldProps>`
    border-radius: ${props => props.icon ? '4px 0px 0px 4px' : '4px'};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px 10px 12px;
    box-sizing: border-box;
    color: ${props =>props.theme.colors.inputGrey};

`;

export const TextFieldContainerInput = styled.div<TextFieldProps>`
    display: flex;
    min-height: 52px;
    font-family: ${props => props.theme.text.font.primary};
    box-sizing: border-box;

    background-color: ${props => props.theme.colors[props.disabled ? 'inputGrey' : 'white']};
    ${props => `
      border: ${props.error || props.check ? '2px' : '1px'} solid ${colors[props.error ? 'red' : props.check ? 'green' : 'grey']};
    `};
    border-radius: 4px;
    cursor: pointer !important;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type=number]{
        -moz-appearance: textfield;
}
    
`;

export const TextFieldContainer = styled.div<TextFieldContainerInputProps>`
    display: flex;
    box-sizing: border-box;
    flex: ${props => props.width && props.fullWidth ? null : 'auto'};
    max-width: ${props => props.fullWidth ? null : '358px'};
    flex-direction: column;
    border-radius: 4px;
    padding: ${props => props.padding};
    position: relative;
    margin: ${props => props.margin};
    width: ${props => props.fullWidth ? '100%' : props.width};
    justify-content: flex-start;

`;

export interface TextFieldLabelProps {
  disabled?: boolean;
}

export const TextFieldLabel = styled.span<TextFieldLabelProps>`
    color: ${props =>props.theme.colors.textFieldLabel};
    padding-bottom: 4px;
    font-size: ${props =>props.theme.text.size.subtitle1};
    font-weight: ${props =>props.theme.text.weight.semiBold};
    font-family: ${props =>props.theme.text.font.primary};
    color: ${props =>props.theme.colors[props.disabled ? 'disabledTextFieldGrey' : 'textFieldLabel']};

`;