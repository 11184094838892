import moment from 'moment';
import { useState, useContext, useEffect } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import errors from '~/constants/errors';
import { AlertToastContext } from '~/context/alertToastContext';
import { CookiesContext } from '~/context/cookiesContext';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import { INuevaOrdenModalError , OrdenDetallesTypes } from '~/interfaces';
import { getUtcDate } from '~/utils';

const useHistorialOrdeneDetalle = (isModal?: boolean, error?: INuevaOrdenModalError, main?: boolean) => {
	// obtienes el listado de órdenes
	const {showSuccess, showError} = useContext(AlertToastContext);
	const navigate = useNavigate();

	const {
		handleShowBack,
		handleCloseNuevaOrdenModalOpen
	} = useContext(GeneralLayoutContext);

	const {macAddress, headersApi, handleDoneInitialLoading, setDataCarrito} = useContext(CookiesContext);
	const apiUrl = import.meta.env.VITE_API_URL;

	const [loading, setLoading] = useState(false);

	// La data se guarda en orden
	const [orden, setOrden] = useState<undefined | OrdenDetallesTypes>(undefined);

	const params = useParams();
	const nFolio = error?.nFolio || params.nFolio;
	const body = {
		nFolio,
	};
	// invoca la data
	useEffect(() => {
		if(!isModal){
			if(macAddress){
				fetch(`${apiUrl}/APPWEB/ObtenerHistorialDetalles`, {
					method: 'POST',
					headers: headersApi,
					body: JSON.stringify(body),

				}).then((res) => res.json()).then((response) => {
					if (response || response.Result) {
						const helper = response.Result.dataHistorialDetalles

				;
						setOrden(helper);
						handleDoneInitialLoading();

					}
			
				}).catch((error) => {
					console.error(error, 'error');
				});
			}

		}
	}, [headersApi.Authorization]);

	// pasa la orden al carrito
	const handleVolverAPedir = () => {
		setLoading(true);

		const body = {
			nFolio: nFolio
		};
			
		fetch(`${apiUrl}/APPWEB/RegistroOrdenesACarrito`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: headersApi,
		}).then((res) => res.json()).then((response) => {
			if(response?.Result?.dataCarrito){
				setDataCarrito(response.Result.dataCarrito);
				setLoading(false);
				
				if(error?.nNivelTienda){
					navigate(`/checkout/${error?.nNivelTienda}`);
					handleCloseNuevaOrdenModalOpen();
					
				} else {
					showSuccess('Tu pedido se ha añadido al carrito');
					navigate('/');

				}

			}
		}).catch((error) => {
			console.error(error, 'error');
		});
	};
	const [loadingReenviar, setLoadingReenviar] = useState(false);

	const handleReenvioTicket = () => {
		setLoadingReenviar(true);

		const body = {
			nFolio: nFolio
		};
			
		fetch(`${apiUrl}/APPWEB/ReenvioTicket`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: headersApi,
		}).then((res) => res.json()).then((response) => {
			setLoadingReenviar(false);
				
			if(response?.Result?.done){
				showSuccess('Tu pedido ha sido reenviado exitosamente.');
			} else {
				setLoadingReenviar(false);
				showError(errors.errorForm);
			}

		}).catch((error) => {
			console.error(error, 'error');
			setLoadingReenviar(false);
			showError(errors.errorForm);
		});
	};

	useEffect(() => {
		if(!isModal){
			if(orden){
				const hoy = moment().format('YYYY-MM-DD');
				const fechaOrden = getUtcDate(orden?.dFechaEntrega, 'YYYY-MM-DD');
			
				handleShowBack(main ? '/' : '/historial-de-pedidos', 'Atrás', orden.error ? 'Pedido cancelado' : ( moment(fechaOrden, 'YYYY-MM-DD').isBefore(moment(hoy, 'YYYY-MM-DD'))) ? 'Pedido completado' : 'Pedido en proceso', true, true);

			} else {
				
				handleShowBack(main ? '/' : '/historial-de-pedidos', 'Atrás', 'Pedido', true, true);
			}
		}
	}, [orden]);
	
    

	return {
		orden,
		loading,
		nFolio,
		handleVolverAPedir,
		loadingReenviar,
		handleReenvioTicket,
	};
};

export default useHistorialOrdeneDetalle;