import styled from 'styled-components';

interface TipoDireccionTagProps {
    disabled?: boolean;
    margin?: string;
    width?: string;
    bigger?: boolean; 
}

const Tag = styled.div<TipoDireccionTagProps>`
    color: ${props => props.theme.colors[props.disabled ? 'grey' : 'green']};
    border: 1px solid ${props => props.theme.colors[props.disabled ? 'grey' : 'green']};
    font-family: ${props =>props.theme.text.font.primary};
    margin: ${props => props.margin};
    font-size: ${props => props.bigger ? props.theme.text.size.subtitle2 : props.theme.text.size.caption};
    border-radius: 5px;
    padding: 1px 4px 1px 4px;
    transition: all 0.3s ease-in-out;
    align-items: center;
    width: ${props => props.width};
    background-color: ${props =>props.theme.colors.white};
`;

export default Tag;