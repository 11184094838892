import React from 'react';
import { CarritoTypes } from '~/interfaces';
import { getCdnUrl, pricePrettify } from '~/utils';

import CardMedia from '../CardMedia';

import Grid from '../Grid';
import QuantityContainer from '../QuantityContainer';
import Typography from '../Typography';
import { CarritoCardContainer } from './styled';

type Props = {
	item: CarritoTypes,
	handleAdd?: () => void,
	checkout?: boolean,
	handleRemove?: () => void,
	loadingLeftSide?: boolean,
	loadingRightSide?: boolean,
	onClick?: () => void
};
const CarritoCard : React.FC<Props> = ({ item, handleRemove, handleAdd, checkout, loadingLeftSide, loadingRightSide, onClick } : Props) => {
	const personalizacion = item.jExtras.length > 0;
	return (
		<CarritoCardContainer className='noselect' checkout={checkout} onClick={onClick}>
			
			<Grid padding='15px 10px 10px 10px' direction='column' container item flex maxwidth={checkout ? '160px' : undefined}>
				<Typography variant='subtitle1bold'>
					{`${item.cDescripcion}`}
				</Typography>
				<Grid padding='5px 0 0 0' container alignItems='center'>
					<Typography padding='5px 0 0 0' variant='subtitle2'>
						{
							`Sub total: ${pricePrettify(item.nTotal || item.nPrecio_Base)}`
						}
					</Typography>
				</Grid>
				{
					personalizacion && <>
						<Typography padding='17px 0 3px 0' variant='subtitle2'>
					Complementos:
						</Typography>
						{
							item.jExtras.map((extra, i) => <Grid  padding='0 5px 0 0' key={i}>
								<Typography variant='regular'>
									{`${extra.cDescripcion} ${`${extra.nCantidad  ? '-' : ''} ${extra.nCantidad || '' }`} ${extra.nPrecio_Base ? `+${pricePrettify(extra.nPrecio_Base)}` : ''}`}
								</Typography>
							</Grid>)
						}
					</>
				}
			</Grid>

			<Grid padding={handleRemove ? (checkout ? '10px 5px 0 5px' : '10px 0 0 0') : '0 0 10px 0'} item width={checkout ? '150px' : '30%'} direction="column" container justifyContent='flex-start'>
				<Grid item >
					<CardMedia alt={item?.cDescripcion} height={'70px'} src={getCdnUrl(item.cImagenUrl)}></CardMedia>
				</Grid>

				{
					handleRemove && handleAdd && <Grid item flex padding='0 0 5px 0'>
						<QuantityContainer
							loadingLeftSide={loadingLeftSide}
							loadingRightSide={loadingRightSide}
							nCantidad={item.nCantidad}
							handleRemove={handleRemove}
							handleAdd={handleAdd}
						></QuantityContainer>
					</Grid>
				}
			</Grid>
		</CarritoCardContainer>
	);
};
export default (CarritoCard);