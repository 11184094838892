// @flow
import React from 'react';
import useDatePicker from './hooks';
import { DatePickerSteps } from './helpers';

import { ContainerCard } from './styled';
import { FechasDisponiblesTypes } from '~/interfaces';
import { MonthPicker } from './MonthPicker';
import { YearPicker } from './YearPicker';
import { DecadePicker } from './DecadePicker';

type Props = {
	handleCLick: (value: string) => void;
	nRangoLibreFechasDisponibles?: boolean;
	dFechasAPartirDe?: string;
	dFechasDisponibles?: FechasDisponiblesTypes[];
	validateDate?: ((val: string) => boolean) | ((dateValue: string, nRangoLibreFechasDisponibles?: boolean, dFechasAPartirDe?: string, dFechasDisponibles?: FechasDisponiblesTypes[]) => boolean);
	value: string | null
	initialStepper?: DatePickerSteps
};

const DatePicker = ({ handleCLick, nRangoLibreFechasDisponibles, dFechasAPartirDe, dFechasDisponibles, validateDate, value, initialStepper = DatePickerSteps.MONTHS }: Props) => {
	const { 
		currentMonth, 
		selectedYear, 
		stepper,

		handleBackMonth,
		handleNextMonth,
		handleBackYear,
		handleNextYear,
		handleBackStepper,
		handleClickMonth,
		handleBackDecade,
		handleClickYear,
		handleNextDecade
	} = useDatePicker(initialStepper, dFechasAPartirDe, dFechasDisponibles);

	return (
		<ContainerCard>
			{
				stepper === DatePickerSteps.MONTHS ? (
					<MonthPicker 
						currentMonth={currentMonth}
						handleCLick={handleCLick}
						selectedYear={selectedYear}
						handleBackMonth={handleBackMonth}
						handleNextMonth={handleNextMonth}
						nRangoLibreFechasDisponibles={nRangoLibreFechasDisponibles}
						dFechasAPartirDe={dFechasAPartirDe}
						dFechasDisponibles={dFechasDisponibles}
						handleBackStepper={handleBackStepper}
						validateDate={validateDate}
						value={value}
					/>
				) : stepper === DatePickerSteps.YEARS ? (
					<YearPicker 
						handleBackStepper={handleBackStepper}
						handleBackYear={handleBackYear}
						handleNextYear={handleNextYear}
						handleClickMonth={handleClickMonth}
						selectedYear={selectedYear}
						value={value}
					/>
				) : stepper === DatePickerSteps.DECADE ? (
					<DecadePicker 
						selectedYear={selectedYear}
						handleBackDecade={handleBackDecade}
						handleNextDecade={handleNextDecade}
						handleClickYear={handleClickYear}
						value={value}
					/>
				) : <></>
			}
		</ContainerCard>
	);
};

export default DatePicker;
