import { CarritoTypes, ConfiguracionProducto, DataCarritoTypes, ProductosProps } from '~/interfaces';

/*
 * GOOGLE TAG MANAGER - PERMISOS
*/
interface consent {
	analytics?: boolean
	personalization?: boolean
	advertising?: boolean
}

export const updateConsent = ({ 
	analytics = false, 
	personalization = false, 
	advertising = false
}: consent) => {
	window.gtag('consent', 'update', {
		functionality_storage: 'granted',
		security_storage: 'granted',
		ad_storage: advertising ? 'granted' : 'denied',
		ad_user_data: advertising ? 'granted' : 'denied',
		personalization_storage: personalization ? 'granted' : 'denied',
		ad_personalization: personalization ? 'granted' : 'denied',
		analytics_storage: analytics ? 'granted' : 'denied',
	});

	localStorage.setItem('cookies_preferences', JSON.stringify({
		analytics,
		personalization,
		advertising
	}));
};

export const getCookiesPreferences = () => {
	try {
		const cookiesPreferencesString = localStorage.getItem('cookies_preferences');
		const cookiesPreferences = JSON.parse(cookiesPreferencesString || '');

		if (!cookiesPreferences || typeof cookiesPreferences !== 'object') {
			throw null;
		}

		const { advertising, analytics, personalization } = cookiesPreferences;
		if (typeof advertising !== 'boolean' || typeof analytics !== 'boolean' || typeof personalization !== 'boolean') {
			throw null;
		}

		return {
			advertising,
			analytics,
			personalization
		};
	} catch (error) {
		localStorage.removeItem('cookies_preferences');
		return null;
	}
};


/*
 * RECOMENDED EVENTS
 * Documentacion: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm
*/ 
type CartEvents = 'view_cart' | 'remove_from_cart' | 'add_to_cart';

const clearEcommerce = () => {
	window.dataLayer.push({
		ecommerce: null
	});
};

export const helperCartEvents = (items: CarritoTypes[], listName: string, event: CartEvents) => {
	clearEcommerce();

	window.dataLayer.push({
		event: event,
		ecommerce: {
			currency: 'MXN',
			value: items?.reduce((acc, item) => acc + item?.nTotal, 0),
			items: items?.map((item, index) => ({
				item_id: item.nVProducto,
				item_name: item.cDescripcion,
				discount: 0,
				index,
				price: item.nPrecio_Base / item.nCantidad,
				quantity: item.nCantidad,
				item_list_name: listName
			})),
		}
	});
};

export const helperSearchEvent = (searchTerm: string, nivelTienda: string) => {
	clearEcommerce();

	window.dataLayer.push({
		event: 'search',
		ecommerce: {
			search_term: searchTerm,
			nivel_tienda: nivelTienda
		}
	});
};

export const helperViewItemList = (items: ProductosProps[], itemList: string) => {
	clearEcommerce();

	window.dataLayer.push({
		event: 'view_item_list',
		ecommerce: {
			items: items?.map((item) => ({
				item_id: item.nVProducto,
				item_name: item.cDescripcion,
				currency: 'MXN',
				price: item.nPrecio_Base || item?.nPrecio_Base_Original,
				discount: (item?.nPrecio_Base_Original && item.nPrecio_Base) ? (item.nPrecio_Base_Original - item.nPrecio_Base) : 0,
				quantity: 1,
			})),
			item_list_name: itemList,
		}
	});
};

export const helperViewItem = (item: ProductosProps, itemList: string) => {
	clearEcommerce();

	window.dataLayer.push({
		event: 'view_item',
		ecommerce: {
			currency: 'MXN',
			value: item.nPrecio_Base || item?.nPrecio_Base_Original || 0,
			items: [{
				item_id: item.nVProducto,
				item_name: item.cDescripcion,
				discount: 0,
				price: item.nPrecio_Base || item?.nPrecio_Base_Original || 0,
				item_list_name: itemList,
				quantity: 1,
			}],
		}
	});
};

export const helperSelectItem = (item: ConfiguracionProducto, itemList: string) => {
	clearEcommerce();

	window.dataLayer.push({
		event: 'select_item',
		ecommerce: {
			currency: 'MXN',
			value: item.producto.nPrecio_Base || item.producto.nPrecio_Base_Original || 0,
			items: [{
				item_id: item.producto.nVProducto,
				item_name: item.producto.cDescripcion,
				discount: 0,
				price: item.producto.nPrecio_Base || item.producto.nPrecio_Base_Original || 0,
				item_list_name: itemList,
				quantity: 1,
			}],
		}
	});
};

export const helperBeginCheckout = ({ carrito, nTotal }: DataCarritoTypes,coupon?: string) => {
	clearEcommerce();

	window.dataLayer.push({
		event: 'begin_checkout',
		ecommerce: {
			currency: 'MXN',
			coupon,
			value: nTotal || 0,
			items: carrito?.map((item, index) => ({
				item_id: item.nVProducto,
				item_name: item.cDescripcion,
				discount: 0,
				price: item.nPrecio_Base / item.nCantidad,
				item_list_name: 'Productos Checkout',
				quantity: item.nCantidad,
				index
			}))
		}
	});
};

export const helperPurchase = ({ carrito, nTotal, nPrecioEnvio }: DataCarritoTypes, token: string,coupon?: string) => {
	clearEcommerce();

	window.dataLayer.push({
		event: 'purchase',
		ecommerce: {
			currency: 'MXN',
			value: nTotal,
			transaction_id: token,
			coupon,
			shipping: nPrecioEnvio,
			tax: undefined,
			items: carrito?.map((item, index) => ({
				item_id: item.nVProducto,
				item_name: item.cDescripcion,
				discount: 0,
				price: item.nPrecio_Base / item.nCantidad,
				item_list_name: 'Productos Checkout',
				quantity: item.nCantidad,
				index
			}))
		}
	});
};