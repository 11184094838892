import React from 'react';
import { ArrowBackIcon, CancelIcon, SearchIcon } from '~/assets/icons';
import { CardMedia, Grid, IconButton, Typography } from '..';
import { MenuItemsContainer, Popover } from '../TextFieldSelect/styled';
import { TextFieldInput, TextFieldContainerInput, InputContainer, MenuItem, TextFieldContainer, Divider, MenuItemImg } from './styled';
import { useTextFieldSearchMain } from '~/hooks';
import { ActiveDivider, NivelTab, ScrollContainer } from '../NivelesAppBar/styled';
import { getCdnUrl } from '~/utils';
type Props = {
	label?: string,
	padding?: string,
	type?: string,
	error?: boolean,
	check?: boolean,
	placeholder?: string,
	margin?: string,
	handleShow?: VoidFunction,
	open?: boolean,
	handleCloseSearch?: VoidFunction,
	xs?: boolean,
	onlyTextfield?: boolean,
	nivelesTiendaId?: number
};
const TextFieldSearchMain : React.FC<Props> = ({error, check, type, placeholder, margin, padding, handleShow,open, handleCloseSearch, xs, onlyTextfield, nivelesTiendaId} : Props) => {
	const icon = error || check;
	const {
		activeTab,
		handleActiveTab,
		mainValue,
		handlemainValue,
		handleKeyPress,
		busquedasPopulares,
		handleBuscarInput,
		handleBusquedaPopular,
		handleGoToCategoria
	} = useTextFieldSearchMain(handleCloseSearch, nivelesTiendaId);

	return (
		<>
			<TextFieldContainer open={open} margin={margin} padding={padding}>
				<Grid container>
					{
						!xs && open && !onlyTextfield && <IconButton onClick={handleCloseSearch}>
							<ArrowBackIcon style={{marginRight: '-5px', marginTop: '-4px'}}></ArrowBackIcon>
						</IconButton>
					}
					<TextFieldContainerInput open={open} error={error} check={check} >
			
						<InputContainer>
							<TextFieldInput
								onKeyPress={handleKeyPress}
								onFocus={handleShow}
								id="MainSearcher" 
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										handleBuscarInput();
									}
								}}
								placeholder={placeholder || 'Comida, pasteles, pan, bebidas, etc.' }autoComplete='off' type={type} icon={icon} onChange={handlemainValue} value={mainValue} />
						</InputContainer>
			
			
						<div className='icon'>
							<IconButton onClick={open ? handleCloseSearch : handleBuscarInput}>
								{
									open ? <CancelIcon color='ocher'></CancelIcon> : <SearchIcon color='grey' />

								}
							</IconButton>
						</div>
				
					</TextFieldContainerInput>
				</Grid>
				<Popover open={open}>
					<Grid item flex container padding={xs ? '25px 16px 0 16px' : '20px 16px 0 16px'}>
						<ScrollContainer padding="0" item flex >
							<div className='container'>
								{
									busquedasPopulares?.map((item, i)=> (
										<NivelTab key={i} active={activeTab.nNivelTienda === item.nNivelTienda} onClick={()=>handleActiveTab(item)} >
											{item.cNivelTienda}
											<ActiveDivider active={activeTab.nNivelTienda === item.nNivelTienda} />
										</NivelTab>
									))
								}
							</div>
							<Divider/>
						</ScrollContainer>
					</Grid>
					<MenuItemsContainer padding='15px 16px 16px 16px' height="417px">
						{
							activeTab.nNivelTienda === 0 ? <>
								<Typography variant='subtitle1regular' padding='0 0 11px 0' color='grey70'>Búsquedas populares:</Typography>
								{
									activeTab?.JCategorias?.map((item, i) =>(
										<Grid key={i} container>
											<MenuItem onClick={()=>handleBusquedaPopular(item.cDescripcion)}>
												{item.cDescripcion}
											</MenuItem>
										</Grid>
									))
								}
								
							</> : <>
								<Typography variant='subtitle1regular' padding='0 0 11px 0' color='grey70'>{`Categorías populares en ${activeTab?.cNivelTienda?.toLocaleLowerCase()}:`}</Typography>
								{
									activeTab?.JCategorias?.map((item, i) =>(
										<MenuItemImg onClick={()=>handleGoToCategoria(item, activeTab.nNivelTienda)} key={i} className='noselect'>
											<Grid width='40px' container>
												<CardMedia alt={item?.cDescripcion} height='40px' src={getCdnUrl(item?.cImagenUrl)} />
											</Grid>
											<Grid item flex>
												<Typography variant='subtitle2'>{item.cDescripcion}</Typography>
											</Grid> 
										</MenuItemImg>
									))
								}
								
								
							</>
						}
					</MenuItemsContainer>

				</Popover>
			
			</TextFieldContainer>
		
		
		</>
	);
};
export default (TextFieldSearchMain);