import { useContext, useState } from 'react';
import { CookiesContext } from '~/context/cookiesContext';
import { CarritoTypes } from '~/interfaces';
import useCarrito from './useCarrito';

const useDetalleProductoModal = (isCheckout?: boolean, nCupon?: number, isCarrito?: boolean, ) => {
	const apiUrl = import.meta.env.VITE_API_URL;
	const {headersApi} = useContext(CookiesContext);

	const {
		handleDataCarrito,
		handleModificaCarrito,
		carritoLoading,
		nivelMainId,
		handleNivelMainId,
		handleOrdenar,
		detalleEnvio,
		dataCarrito,
		loadingCheckoutCarrito,
	} = useCarrito(isCheckout, undefined, nCupon, isCarrito);

	const handleLike = (nVProducto: number, handleFinish?: VoidFunction) => {
		const body = {
			'nVProducto': nVProducto
		};		

		fetch(`${apiUrl}/APPWEB/ModificaFavoritos`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: headersApi,
		}).then((res) => res.json()).then((response) => {

			if (response?.Result) {
				if(handleFinish){
					handleFinish();
				}
			}
		}).catch((error) => {
			console.error(error, 'error');
		});
	};
	// Te lleva al detalle del producto
	const [openDetalleProducto, setOpenDetalleProducto] = useState(false);
	const [productoId, setProductoId] = useState(0);

	const handleProductoId = (value: number) => {
		setProductoId(value);
	};

	const handleClick = (id: number, nivelId?: number) => {
		
		setOpenDetalleProducto(true);
		setProductoId(id);
		
		if(nivelId && handleNivelMainId){
			handleNivelMainId(nivelId);
		}
	};

	const handleOpenDetalleProductoModal = (value: boolean) => {
		setOpenDetalleProducto(value);
	};

	const [carritoItem, setCarritoItem] = useState<CarritoTypes | undefined>(undefined);

	const handleOpenDetalleProducto = (item: CarritoTypes, nivelId?: number) => {
		setCarritoItem(item);
		handleClick(item.nVProducto, nivelId);
	};
	

	return {
		handleLike,
		openDetalleProducto,
		productoId,
		handleOpenDetalleProductoModal,
		handleProductoId,
		carritoItem,
		handleOpenDetalleProducto,
		handleClick,
		handleDataCarrito,
		handleModificaCarrito,
		carritoLoading,
		dataCarrito,
		nivelMainId,
		handleOrdenar,
		handleNivelMainId,
		detalleEnvio,
		loadingCheckoutCarrito,
	};
};

export default useDetalleProductoModal;