import React from 'react';
import { Button, CardMedia, CloseButton, Grid, Modal } from '..';
import { IComplementoModalStatus } from '~/interfaces';
import { ModalContainer } from './styled';
import { useNavigate } from 'react-router-dom';
import { getCdnUrl } from '~/utils';

type Props = {
    handleClose: VoidFunction,
	complementoModalStatus: IComplementoModalStatus,
    loading?: boolean,
};

const CategoriaComplementoModal : React.FC<Props> = ({ 
	handleClose,
	complementoModalStatus,
	loading,
}) => {
	const navigate = useNavigate();

	const handleAcept = () => {
		navigate(`/tienda/${complementoModalStatus.nNivelTienda}/categoria/${complementoModalStatus.nCategoriaComplemento}`);
		handleClose();
	};

	return (
		<Modal
			width={'100%'}
			maxWidth={'341px'}
			open={complementoModalStatus.open}
			handleClose={handleClose}
			action={<Grid container spacing='5px' padding='35px 15px 15px 15px'>
				<Grid item flex>
					<Button fullWidth variant='outlined-round' onClick={handleClose}>
						Mmm… Al ratito
					</Button>
				</Grid>
				<Grid item flex>
					<Button loading={loading} fullWidth variant='contained-round' onClick={handleAcept}>
						¡Sí!, Quiero ver
					</Button>
				</Grid>     
			</Grid>}
		>
			<ModalContainer>
				<div className='header'><CloseButton color='white' onClick={handleClose} /></div>
				{
					complementoModalStatus.cImagenUrlCategoriaComplemento && <CardMedia alt='Añadir complemento' height='335px' borderRadius='22px' maxwidth='100%' src={getCdnUrl(complementoModalStatus.cImagenUrlCategoriaComplemento)} />
				}
			</ModalContainer>
		</Modal>
	);
};
export default (CategoriaComplementoModal);