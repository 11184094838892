import React from 'react';
import { GeneralLoadingContainer, ProgressBar } from './styled';
import Logo from '../../assets/images/logo.webp';


type Props = {
	showGeneralLoading?: boolean,
};

const GeneralLoading : React.FC<Props> = ({ showGeneralLoading } : Props) => {
	return (
		<GeneralLoadingContainer showGeneralLoading={showGeneralLoading}>
			<img loading="lazy" draggable="false" width="174px" alt='Logo Panamá' src={Logo} />
			<ProgressBar >
				<div></div>
			</ProgressBar>
		</GeneralLoadingContainer>
	);
};
export default (GeneralLoading);
