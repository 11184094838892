import styled, { css } from 'styled-components';

export interface ModalProps {
  open?: boolean;
}
export interface ModalContainerProps {
  open?: boolean;
  height?: string;
    
}

export interface ModalModalBodyContainer {
    padding?: string;
    
}
export const ModalContainer = styled.div<ModalContainerProps>`
    display: flex;
    flex-direction: column;
    visibility: hidden;

    transition: all 0.3s ease-in-out;
    background: ${props => props.theme.colors.white};
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 0px;
    box-sizing: border-box;

    z-index: 10002;
    ${props => props.open ? css`
    visibility: visible;
    width: 400px;
    @media (max-width: 824px) {
        width: 100vw;
    }
    opacity: 1;
  ` : null}
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    overflow: hidden;

    .shadowBottom {
      box-shadow: 0px 3px 6px #0000000D;

    }
    .containerWidth {
      width: 400px;

      @media (max-width: 824px) {
        width: 100vw;
      }

    }


    
`;


export const ModalBodyContainer = styled.div<ModalModalBodyContainer>`
    display: flex;
    flex: 1 !important;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 400px;
    @media (max-width: 824px) {
        width: 100vw;
    }
    overflow-y: auto;
    ::-webkit-scrollbar {
          -webkit-appearance: none;
      }
          
      ::-webkit-scrollbar:vertical {
          width: 8px;
      }
          
      ::-webkit-scrollbar:horizontal {
          height: 12px !important;
      }
          
      ::-webkit-scrollbar-thumb {
          background-color: ${props => props.theme.colors.primary};
          border: 0px;

      }
          
      ::-webkit-scrollbar-track {
          border-radius: 0px;
          background-color: ${props => props.theme.colors.borderLightGrey};
      }

`;

export const CarritpContainer = styled.div`
      padding: 15px 25px 130px 25px;
      gap: 5px;
      display: flex;
      flex-direction: column;
      position: relative;
      box-sizing: border-box;
      min-width: 350px;
      @media (max-width: 650px) {
        padding: 15px 25px 100px 25px;
    }
`;

export const Modal = styled.div<ModalProps>`
  visibility: hidden;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1001;
  ${props => props.open ? `
    visibility: visible;
  ` : null}
`;

export const CarritoMenuContainer = styled.div`
    background: ${props => props.theme.colors.white};
    display: flex;
    box-sizing: border-box;
    padding: 16px;
    border: 1px solid ${props => props.theme.colors.borderLightGrey};
    border-radius: 22px;
    cursor: pointer;
    .paragraphContainer {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      box-sizing: border-box;
      max-height: 34px;
    }
    margin-bottom: 8px;

`;

export const ImgContainer = styled.div`
        border-radius: 15px;
        height: 40px;
        z-index: 2;
        width: 40px;
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        border: 1px solid ${props => props.theme.colors.grey70};
`;


export const CantidadContainer = styled.div`
        aspect-ratio: 1;
        border-radius: 50%; 
        height: 40px;
        z-index: 2;
        width: 40px;
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        background-color: ${props => props.theme.colors.primary};

`;