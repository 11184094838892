import React, { useEffect } from 'react';
import { CarritoTypes, DataCarritoTypes, DetalleEnvioType, handleModificaCarritoType} from '~/interfaces';
import DetalleProducto from '~/views/tienda/containers/detalleProducto';
import Loading from '../Loading';
import Modal from '../Modal';
import { useDetalleProducto } from '~/hooks';
import { helperSelectItem } from '~/utils';
type Props = {
	isCheckout?: boolean, 
	nCupon?: number, 
	isCarrito?: boolean,
	handleRemove?: (item: CarritoTypes, handleModificaCarrito: handleModificaCarritoType, handleCloseDetalleProducto: () => void) => void
	loadingRemove?: boolean
	productoId: number,
	handleOpenDetalleProductoModal: (value: boolean) => void,
	handleProductoId: (value: number) => void,
	openDetalleProducto: boolean,
	carritoItem?: CarritoTypes | undefined,
	handleClick: (id: number, nivelId?: number, handleNivelMainId?: ((value: number | null) => void) | undefined) => void
	handleDataCarrito: (nCupon?: number | undefined) => void,
	handleModificaCarrito: handleModificaCarritoType,
	carritoLoading: boolean,
	nivelMainId: number | null,
	handleOrdenar: (carrito: DataCarritoTypes, detalleEnvio: DetalleEnvioType, nombres: string, numeroCelular: string, nCupon?: number | undefined, handleDone?: VoidFunction | undefined) => void,
	handleNivelMainId: (value: number | null) => void,
	detalleEnvio: DetalleEnvioType | null,
	loadingCheckoutCarrito: boolean,
	itemList: string
};
const DetalleProductoModal : React.FC<Props> = ({ 
	loadingRemove,
	handleRemove,
	productoId,
	handleOpenDetalleProductoModal,
	handleProductoId,
	handleClick,
	openDetalleProducto,
	carritoItem,
	handleDataCarrito,
	handleModificaCarrito,
	carritoLoading,
	nivelMainId,
	handleNivelMainId,
	handleOrdenar,
	detalleEnvio,
	loadingCheckoutCarrito,
	itemList,
} : Props) => {
	const apiUrl = import.meta.env.VITE_API_URL;

	const {
		dataProducto,
		handleCheckVariantes,
		handlePlus,
		handleCantidad,
		cantidad,
		handleMinus,
		handleAddExtras,
		handleCheckModificadores,
		handleCloseDetalleProducto,
		handlePanaBoxDestinatario,
		handlePanaBoxRemitente,
		comentario,
		handleComentario,
		handleAddComplementos,
		nombreDelFestejado,
		panaBoxDestinatario,
		panaBoxRemitente,
		handleNombreDelFestejado,
		handleEdad,
		handleEdadPlus,
		handleEdadMinus,
		edad,
		nombreCumpleaniero,
		handleNombreCumpleaniero,
		errorNombreDelFestejado,
		errorNombreCumpleaniero,
		errorPanaBoxDestinatario,
		errorPanaBoxRemitente,
		errorComentario,
	} = useDetalleProducto(
		productoId,
		handleOpenDetalleProductoModal,
		handleProductoId,
		handleClick, 
		handleDataCarrito,
		handleModificaCarrito,
		carritoLoading,
		nivelMainId,
		handleOrdenar,
		detalleEnvio,
		loadingCheckoutCarrito,
		handleNivelMainId, 
		carritoItem
	);

	useEffect(() => {
		if (dataProducto) {
			helperSelectItem(dataProducto, itemList);
		}
	}, [dataProducto]);

	return (
		<Modal responsiveFullSize width='100%' maxWidth='1080px' height='90vh' open={openDetalleProducto} handleClose={handleCloseDetalleProducto}>
			{
				dataProducto ? (
					<DetalleProducto 
						handleComentario={handleComentario}
						comentario={comentario}
						dataProducto={dataProducto}
						openDetalleProducto={openDetalleProducto}
						carritoItem={carritoItem}
						handleClose={handleCloseDetalleProducto}
						apiUrl={apiUrl}
						handleCheckVariantes={handleCheckVariantes}
						handlePlus={handlePlus}
						handleCantidad={handleCantidad}
						cantidad={cantidad}
						handleEdad={handleEdad}
						handleEdadPlus={handleEdadPlus}
						handleEdadMinus={handleEdadMinus}
						handlePanaBoxDestinatario={handlePanaBoxDestinatario}
						handlePanaBoxRemitente={handlePanaBoxRemitente}
						edad={edad}
						handleMinus={handleMinus}
						handleAddExtras={handleAddExtras}
						handleAddComplementos={handleAddComplementos}
						handleCheckModificadores={handleCheckModificadores}
						handleModificaCarrito={handleModificaCarrito}
						carritoLoading={carritoLoading}
						nombreDelFestejado={nombreDelFestejado}
						panaBoxDestinatario={panaBoxDestinatario}
						panaBoxRemitente={panaBoxRemitente}
						handleNombreDelFestejado={handleNombreDelFestejado}
						handleRemove={handleRemove}
						loadingRemove={loadingRemove}
						nombreCumpleaniero={nombreCumpleaniero}
						handleNombreCumpleaniero={handleNombreCumpleaniero}
						errorNombreDelFestejado={errorNombreDelFestejado}
						errorNombreCumpleaniero={errorNombreCumpleaniero}
						errorComentario={errorComentario}
						errorPanaBoxDestinatario={errorPanaBoxDestinatario}
						errorPanaBoxRemitente={errorPanaBoxRemitente}
					/>
				) : (
					<Loading minWidth='100%' />
				)
			}
		</Modal>
	);
};
export default (DetalleProductoModal);