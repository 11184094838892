import React, { useState, createContext, } from 'react';
import { statusDialogPropsInitialValue } from '~/constants/contents';

export type UserInfoType = {
	cNombre?: string,
	cApellido_Paterno?: string,
	cApellido_Materno?: string,
	nGenero?: number,
	cCorreo?: string,
	cNumero_Celular?: string,
	nEstatus?: number,
	dFecha_Nacimiento?: Date,
	cImg_Perfil?: string
}

type statusDialogPropsInitialValueType = {
    open: boolean,
    error: boolean,
    helperText: string,
}


export type AlertToastContextProps = {
	statusDialogProps: statusDialogPropsInitialValueType,
	showError: (errorText: string) => void,
	showSuccess: (successText: string) => void,
	handleCloseAlert: () => void,

	showBlackAlert: (successText: string) => void,
	statusBlackDialogProps: statusDialogPropsInitialValueType,
	handleCloseBlackAlert: () => void,

};
type PropsChildren = {
    children: JSX.Element | JSX.Element[],
};

export const AlertToastContext = createContext({} as AlertToastContextProps);
//invoca desactiva las alertas globales ya sean de error o correctas.
export const AlertToastProvider = ({children}: PropsChildren) => {


	const [statusDialogProps, setStatusDialogProps] = useState(statusDialogPropsInitialValue);

	const [statusBlackDialogProps, setStatusBlackDialogProps] = useState(statusDialogPropsInitialValue);

	const showError = (errorText: string) => {
		setStatusDialogProps({
			open: true,
			error: true, 
			helperText: errorText,
		});
	};

	const showSuccess = (successText: string) => {
		setStatusDialogProps({
			open: true,
			error: false, 
			helperText: successText,
		});
	};

	const showBlackAlert = (errorText: string) => {
		setStatusBlackDialogProps({
			open: true,
			error: true, 
			helperText: errorText,
		});
	};

	const handleCloseBlackAlert = () => {
		setStatusBlackDialogProps(statusDialogPropsInitialValue);
	};


	const handleCloseAlert = () => {
		setStatusDialogProps((prevState => {
			const copy = {...prevState};
			copy.open = false;
			return copy;
		}));
	};

	return <AlertToastContext.Provider
		value={{
			statusDialogProps,
			showError,
			showSuccess,
			handleCloseAlert,
			statusBlackDialogProps,
			showBlackAlert,
			handleCloseBlackAlert,

		}}>
		{children}
	</AlertToastContext.Provider>;
};

export default (AlertToastProvider);
