import React from 'react';
import { ControlLabel, Grid, TextField, TextFieldSelect, Title } from '~/components';
import TextFieldSearch from '~/components/TextFieldSearch';

import { Colonias, ErrorTextFieldType, FuncChangeEventType, FuncChangeSelectType, SelectItemsTypes } from '~/interfaces';
import { numberInputHelpers } from '~/utils';

type Props = {
    codigoPostal: string,
	handleCodigoPostal: FuncChangeEventType,
	colonia: SelectItemsTypes,
	handleColonia: FuncChangeSelectType,
	calle: string,
	handleCalle: FuncChangeEventType,
	numeroExterior: string,
	handleNumeroExterior: FuncChangeEventType,
	numeroInterior: string,
	handleNumeroInterior: FuncChangeEventType,
	entreCalle: string,
	handleEntreCalle: FuncChangeEventType,
	tipoDomicilio: SelectItemsTypes,
	handleTipoDomicilio: (e: SelectItemsTypes) => void,
	dataColonias: Colonias | undefined,
	indicadoresAdicionales: string,
	handleIndicacionesAdicionales: FuncChangeEventType,
	dataTipoDomicilios: SelectItemsTypes[] | undefined,
	errorCodigoPostal: ErrorTextFieldType,
	erroCalle: ErrorTextFieldType,
	errorNumeroExt: ErrorTextFieldType,
	errorNumeroInt: ErrorTextFieldType,
	errorEntreCalle: ErrorTextFieldType,
	errorIndic: ErrorTextFieldType,
	handleDataColonias: VoidFunction,
    loading: boolean,
	loadingColonias?: boolean,
	edit?: boolean,
	gralDisabled?: boolean,
	predeterminada?: boolean,
	direccionPredeterminada?: boolean,
	handleDireccionPredeterminada?: () => void,
	disabledDireccionPredeterminada?: boolean,
};

/* 
-Que cuando borre el cp, se resetee los demás datos
-

*/
const NuevaDireccion : React.FC<Props> = ({ 
	codigoPostal,
	edit,
	handleCodigoPostal,
	colonia,
	handleColonia,
	dataColonias,
	calle,
	handleCalle,
	numeroExterior,
	handleNumeroExterior,
	numeroInterior,
	handleNumeroInterior,
	entreCalle,
	handleEntreCalle,
	tipoDomicilio,
	handleTipoDomicilio,
	indicadoresAdicionales,
	handleIndicacionesAdicionales,
	dataTipoDomicilios,
	errorCodigoPostal,
	erroCalle,
	errorNumeroExt,
	errorNumeroInt,
	errorEntreCalle,
	errorIndic,
	handleDataColonias,
	loadingColonias,
	loading,
	gralDisabled,
	predeterminada,
	direccionPredeterminada,
	handleDireccionPredeterminada,
	disabledDireccionPredeterminada,
} : Props) => {
	return (
		<Grid key={1} container direction='column' padding='0 0 60px 0'>
			<Grid container justifyContent='center'>
				<Title variant='h5-medium' padding={'0 0 5px 0'} >
					Ingresa tu código postal
				</Title>
			</Grid>

			<Grid container justifyContent='center'>
				<TextFieldSearch 
					fullWidth
					padding='10px 0 10px 0' 
					{...(loadingColonias ? {helperText: 'Cargando colonias...'} : {...errorCodigoPostal})}
					placeholder='Ingresa tu código postal' 
					value={codigoPostal} 
					{...numberInputHelpers}
					onChange={handleCodigoPostal} 
					handleBuscarInput={handleDataColonias}
				/>
			</Grid>
			
			{
				dataColonias?.cMunicipio && <>
					{/* <TextField disabled padding='0 0 10px 0' label='Estado' value={dataColonias?.cEstado || ''}></TextField> */}

					<TextField 
						fullWidth 
						edit={edit} 
						disabled 
						padding='0 0 10px 0' 
						label='Ciudad' 
						value={dataColonias?.cMunicipio || ''} 
					/>

					<TextFieldSelect
						fullWidth
						disabled={loading || gralDisabled}
						padding='0 0 10px 0'
						id='colonias'
						items={dataColonias?.jColonias || []}
						label='Colonia'
						value={colonia}
						onChange={handleColonia}
					/>

					{
						colonia.nValor ? <>
							<TextField 
								fullWidth 
								placeholder='Ingresa tu calle' 
								edit={edit} 
								disabled={loading || gralDisabled} 
								{...erroCalle} 
								padding='0 0 10px 0' 
								label='Calle' 
								onChange={handleCalle} 
								value={calle}
							/>

							<TextField 
								fullWidth
								placeholder='Ingresa el número exterior' 
								edit={edit} 
								disabled={loading || gralDisabled}
								{...errorNumeroExt}
								padding='0 0 10px 0' 
								label='Número exterior' 
								{...numberInputHelpers}
								onChange={handleNumeroExterior} 
								value={numeroExterior}
							/>

							<TextField 
								fullWidth
								edit={edit} 
								disabled={loading || gralDisabled} 
								placeholder='Ingresa el número interior' 
								{...errorNumeroInt}
								padding='0 0 10px 0' 
								label='Número interior (opcional)' 
								{...numberInputHelpers}
								onChange={handleNumeroInterior} 
								value={numeroInterior}
							/>

							<TextField 
								fullWidth 
								edit={edit} 
								disabled={loading || gralDisabled}
								placeholder='Ingresa' 
								{...errorEntreCalle}
								padding='0 0 10px 0' 
								label='¿Entre qué calles está?' 
								onChange={handleEntreCalle} 
								value={entreCalle}
							/>

							<TextField fullWidth 
								{...errorIndic}
								edit={edit} 
								disabled={loading || gralDisabled}
								padding='15px 0 10px 0' 
								label='Detalles (Depto., negocio, color, etc.)' 
								placeholder='Ingresa referencias de tu dirección'
								onChange={handleIndicacionesAdicionales} 
								value={indicadoresAdicionales}
							/>

							<TextFieldSelect
								fullWidth
								disabled={loading || gralDisabled}
								padding='0 0 10px 0'
								id='tipoDomicilio'
								items={dataTipoDomicilios || []}
								label='Tipo de domicilio'
								value={tipoDomicilio}
								onChange={handleTipoDomicilio}
							/>

							{
								predeterminada && handleDireccionPredeterminada && (
									<ControlLabel 
										padding="16px 0 0 0" 
										disabled={disabledDireccionPredeterminada || loading || gralDisabled} 
										onClick={handleDireccionPredeterminada} 
										checked={direccionPredeterminada} 
										radio
									>
										Dirección de envío predeterminada
									</ControlLabel>
								)
							}
						</> : null
					}	
					
				</>
			}
		</Grid>
	);
};

export default (NuevaDireccion);