import styled, { css } from 'styled-components';

export interface ModalProps {
  open?: boolean;
}
export interface ModalContainerProps {
    height?: string;
    maxWidth?: string;
    responsiveFullSize?: boolean;
    width?: string;
    
}

export interface ModalModalBodyContainer {
    padding?: string;
    
}
export const ModalContainer = styled.div<ModalContainerProps>`
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    ${props => props.height ? css`
    height: ${props.height};
    ` : css`
    max-height: 708px; min-height: 210px; 
    `}
    
    width: auto;
    max-width: ${props => props.maxWidth ? props.maxWidth : '1080px'};
    background: ${props => props.theme.colors.white};
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-sizing: border-box;
    z-index: 10001;
    position: relative;

    width: ${props => props.width ? props.width : css`
        min-width: 500px;
        @media (max-width: 900px) {
          min-width: 300px;
          
        }
    `};
    ${props => props.responsiveFullSize ? css`
      @media (max-width: 600px) {
        height: 100%;
        border-radius: 0px;
        width: 100vw;
      }
    ` : null}
`;


export const ModalBodyContainer = styled.div<ModalModalBodyContainer>`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${props => props.padding};
    z-index: 10002;
    box-sizing: border-box;
    overflow-y: auto;
`;

export const Modal = styled.div<ModalProps>`
  visibility: hidden;
  opacity: 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10000;
  background: rgba(77, 77, 77, .7);
  transition: visibility 0.4s ease;
  ${props => props.open ? `
    visibility: visible;
    opacity: 1;
  ` : null}
`;