import styled, { keyframes } from 'styled-components';

export interface OptionContainerProps {
    margin?: string;
}

export const OptionContainer = styled.div<OptionContainerProps>`
    border: 1px solid ${props => props.theme.colors.borderLightGrey};
    border-radius: 22px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: ${props => props.margin};
    
`;

const show = keyframes`
from {
    opacity: 0;    
}
to {
    opacity: 1;    
}
`;

export const ShowContainer = styled.div`
    animation: ${show} 0.2s forwards;

`;