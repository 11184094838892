import { useEffect, useState } from 'react';

const useEdit = (onChange?: any, defaultValue?: boolean) => {
	const [editValue, setEdit] = useState(defaultValue);
	const handleEditOnChange = (e: any) => {
		if(onChange){
			onChange(e);
		}
		setEdit(false);
	};

	useEffect(() => {
		if(defaultValue){
			setEdit(defaultValue);
		}
	}, [defaultValue]);
	

	return {
		editValue,
		handleEditOnChange,
	};
};

export default useEdit;