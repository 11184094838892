import styled, { css } from 'styled-components';

export interface CarrouselCardContainerProps {
    marginleft?: string | null;
    notCarrousel?: boolean 
}

export interface ItemContainerProps {
    notCarrousel?: boolean,
    gap?: string
}
export interface ChevronButtonProps {
    left?: boolean;
}

export const CarrouselHiddenContainer = styled.div<CarrouselCardContainerProps>`
    height: 100%;
    width: 100%;
    overflow: hidden;
    flex: 1;
    align-items: center;
`;

export const CarrouselCardContainer = styled.div<CarrouselCardContainerProps>`
    display: flex;
    position: relative;
    box-sizing: border-box;
    align-items: center;
   
    .div {
        width: 100%;
        display: flex;
        transition: margin-left 0.7s ease-in-out;
        box-sizing: border-box;
        margin-left: ${props => props.marginleft};

        justify-content: ${props => props.notCarrousel ? 'center' : 'flex-start'};
        z-index: 1;
        height: 100%;
        box-sizing: border-box;
    }
`;

// Si cambian el size del botón 35px cambien el 35px
export const ChevronButton = styled.div<ChevronButtonProps>`
    
    position: absolute;
    z-index: 3;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    ${props => props.left ? css`
        left: 0;
    ` : css`
        right: 35px;
    ` }
    .div {
        background-color: ${props => props.theme.colors.primary};
        position: absolute;
        display: flex;
        height: 40px;
        justify-content: center;
        align-items: center;
        width: 35px;
        cursor: pointer;
        ${props => props.left ? css`
            border-radius: 0px 100% 100% 0px;
        ` : css`
            border-radius: 100% 0px 0px 100%;
        ` }
    }
`;

export const ItemContainer = styled.div<ItemContainerProps>`
    flex: 1 0 100%;
    flex-basis: 100%;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;

    display: flex;

    justify-content: ${props => props.notCarrousel ? 'flex-start' : 'space-between'};
    gap: ${props => props.notCarrousel ? props.gap || '5px' : null};
`;
export interface ScrollContainerProps {
    margin?: string;
}
  
export const ScrollContainer = styled.div<ScrollContainerProps>`
   display: flex;
   box-sizing: border-box;
   width: 100%;
   margin: ${props => props.margin};
   align-items: center;
   overflow-y: auto;
   .scrollItem { margin-right: 6px;}
    display: inline-flexbox;
   padding-bottom: 15px;
`;